.container {
  top: -4px;

  & .input-label {
    margin-bottom: -7px;
  }

  & input:disabled {
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
  }
}

.user-modal-container {
  .input-mask-field {
    .input-container {
      padding: 0px 0px;
    }
  }

  .profile__data_card {
    padding-right: 0px;

    .profile__data-tooltip {
      margin-bottom: 4px !important;
    }

    .contact-copy {
      position: absolute;
      cursor: pointer;
      right: 10px;
    }
  }
}

.modal-header.user-info {
  margin-bottom: 7px;
}

.modal__body {
  padding-top: 16px !important;
}

.modal .form input {
  margin-top: 0;
}

.top-block {
  display: flex;
  flex-direction: row;
  width: 100%;

  .avatar-wrap {
    margin-right: 50px;

    .avatar-label {
      font-size: 12px;
      color: rgba(127, 127, 127, 1);
      margin-bottom: 3px;
    }
  }

  .fields {
    position: relative;
    width: 100%;

    .input-fullName {
    }

    .profile__data-tooltip {
      margin-bottom: -7px;
    }

    .ant-picker-suffix {
      content: url(/assets/img/icon-calendar-custom.svg);
      left: 0;
      top: 11px;
      height: 24px;
      width: 24px;
      position: absolute;
    }

    .ant-picker.ant-picker-disabled {
      cursor: default;
    }

    .ant-picker {
      background-color: #f8f8f8;
      max-height: 46px;
    }

    .ant-picker-input {
      position: relative;
      padding-left: 30px !important;
    }
  }
}

.bottom-block {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > div {
    width: 48%;
  }

  .user-info-btn {
    width: 100%;

    &.info-left {
      margin-top: 10px;
    }

    &.info-right {
      margin-top: 30px;
    }
  }
}

.form.container {
  .user-info-btn,
  .btn-danger:not(:disabled):not(.disabled):active {
    &:hover,
    &:active,
    &::before,
    &:hover::before {
      background-color: #f9e5ea;
      border-color: #f9e5ea;
      transition: none;
      color: #9e2743;
    }
  }
}
