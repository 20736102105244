.secret-mod-controller {


    .custom-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    h3 { 
        margin-right: 20px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
    }
}