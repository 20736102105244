.selected-rows-menu-btn {
  background-color: #f8f8f8;
  border-radius: 10px;
  height: 46px;
  width: 46px;
  border: none;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }

  .double-check-icon {
    width: 34px;
    height: 30px;
  }

  .reassign-icon {
    height: 26px;
  }

  .double-paper-icon {
    height: 28px;
  }

  .printer-icon {
    height: 27px;
  }

  .hard-delete-icon {
    height: 34px;
  }

  .user-unlock-icon {
    width: 33px;
    height: 24px;
  }

  .lock-icon,
  .unlock-icon,
  .white-list-icon {
    svg {
      width: 24px;
      height: 24px;
      color: #888888;
    }
  }
}
