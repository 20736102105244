.table-task td {
  // white-space: nowrap;
}

.table-task {
  tr {
    td:nth-child(2) {
      padding: 0;
    }
    th:nth-child(2) {
      padding: 0;
    }
  }
}

.row-cursor {
  cursor: pointer;
}

.table-task tbody tr td:first-child {
  // text-align: center;
}

.table-task tbody tr td .lnr {
  color: rgba(0, 0, 0, 0.65);
}

.table-task tbody tr td .fas {
  // color: rgba(0, 0, 0, 0.65);
}

.table-task tbody tr td .fa-star {
  // color: black;
  color: #f6da6e;
  cursor: pointer;
}

.table-row-red {
  color: #ba0000;
}

.table-row-bold {
  font-weight: bold;
}

.table-row-gray {
  color: #c6c3c6;
}

.popup {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  z-index: 999;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.fa-paperclip {
}

.selected-ips-menu {
  display: flex;
}

.radio-btn-default {
  border-radius: 10px;
  padding: 6px 20px 0px 20px;
  height: 46px;
  font-size: 16px;
  color: #7f7f7f;
  border-color: #7f7f7f;
  width: 156px;
}

.checked-radio-btn {
  border-radius: 10px;
  padding: 6px 20px 0px 20px;
  height: 46px;
  font-size: 16px;
  color: #9e2743;
  border-color: #9e2743;
  width: 156px;
}
