.contact-row {
  flex-wrap: nowrap;
}

.contact {
  .tabs .tabs__wrap {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
