.count-task-user {
  display: flex;
  flex-direction: column;

  margin: 0px 0px 0px 5px;
  height: 100%;
  min-width: 335px;
  border-radius: 20px;

  padding: 10px;
  background-color: white;

  .title {
    color: #3b3b3b;
    font-weight: 500;
    margin: 10px auto 20px auto;
    line-height: 24px;
    font-size: 16px;
    width: 70%;
    text-align: center;
  }
  .form-content {
    padding: 0px 10px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    .start-date {
      margin-right: 10px;
      width: 50%;

      .ant-picker-suffix {
        content: url(/assets/img/icon-calendar.svg);
        left: 0;
        top: 13px;
        height: 18px;
        width: 18px;
        filter: invert(49%) sepia(1%) saturate(0%) hue-rotate(176deg)
          brightness(101%) contrast(81%);
      }
    }

    .end-date {
      margin-left: 10px;
      width: 50%;

      .ant-picker-suffix {
        content: url(/assets/img/icon-calendar.svg);
        left: 0px;
        top: 13px;
        height: 18px;
        width: 18px;
        filter: invert(49%) sepia(1%) saturate(0%) hue-rotate(176deg)
          brightness(101%) contrast(81%);
      }
    }
  }
  .search-btn {
    padding: 0px 10px;
    display: flex;
    justify-content: center;

    .btn {
      width: 100%;
      color: #9e2743;
    }
  }

  .print-btn {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .btn {
      width: 100%;
      color: #9e2743;
    }
  }

  .content-block {
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0px;
    color: #7f7f7f !important;
    .content-row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;

      .title-row {
        font-size: 12px;
        font-weight: 400;
      }

      .value-row {
        font-size: 12px;
        font-weight: 500;
      }
    }
    .actions-print {
      display: flex;
      align-items: flex-end;
      .btn {
        margin: 0;
      }
    }
  }

  .print-content-block {
    // padding: 50px;
    color: black;
    font-size: 16px;
  }
  .print-header-block {
    font-size: 18px;
    text-decoration: underline;
    font-weight: 500;
  }
}

.count-task-user .ant-picker .ant-picker-input {
  height: 46px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 10px;
}
