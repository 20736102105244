.row-contact-user {
  width: "100%";
  margin: 0;
  margin-top: 10px;
  //   margin-bottom: 100px;
}

.contact__action-btns {
  .contact__btn,
  .btn-danger:not(:disabled):not(.disabled):active {
    &:hover,
    &:active,
    &::before,
    &:hover::before {
      background-color: #f9e5ea;
      border-color: #f9e5ea;
      transition: none;
      color: #9e2743;
    }
  }
}
