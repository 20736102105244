.success-notification , .error-notification {
  background-color: #9E2743;
  color: #FFFFFF;
  border-radius: 25px;

  .ant-notification-notice-message {
    color: #FFFFFF;
  }

  .ant-notification-notice-close-x {
    color: #FFFFFF;
  }
}