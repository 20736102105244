* {
  font-family: Gilroy, sans-serif;
}

html {
  --antd-wave-shadow-color: "transparent";
  scrollbar-width: none;
}

.body {
  font-size: 12px;
}

::-webkit-scrollbar {
  display: none;
}

// .modal ::-webkit-scrollbar {
//   display: auto
// }

iframe {
  pointer-events: none;
}

// .modal .form input,
// .modal .form textarea,
// .modal .form__form-group-button {
//   border: 1px solid #f2f4f7;
//   color: #646777;
// }
.ant-spin-dot-item {
  background-color: #9e2743;
}

.ant-spin-text {
  color: #9e2743;
}

.ant-table-body {
  overflow: hidden;
  overflow-x: auto;
}

.ant-picker-date-panel {
  .ant-picker-header-view button {
    &:hover {
      color: #9e2743;
    }
  }
}

.modal .checkbox-btn__checkbox-custom {
  border: 1px solid #ddd;
}

.modal .chat__bubble-message-wrap {
  background-color: rgba(242, 244, 247, 0.65);
}

.modal .chat__field-input {
  background-color: #f2f4f7;
  border: 1px solid #f2f4f7;
}

.modal .tabs {
  overflow: hidden !important;
}

.ant-select-allow-clear
  .ant-select-selection--single
  .ant-select-selection-selected-value {
  font-size: 12px;
}

.multi-select {
  .ant-select-selection-item {
    height: 30px;
    background-color: #ebebeb;
    color: #3b3b3b;
    line-height: 16px;
    align-items: center;
    margin: 3px 4px;
    border-radius: 5px;
  }
}

p.ant-empty-description {
  display: none;
}

// .ant-picker.ant-input {
//   border: 1px solid #f2f4f7;
// }
.theme-light .card-body {
  // padding: 15px;
}

.ant-select-selection__choice__content {
  font-size: 12px;
}

.ant-select.select,
.ant-select.ant-select-single,
.ant-select.ant-select-multiple,
.ant-select.ant-tree-select {
  align-items: center;
  .ant-select-selector {
    background-color: #f8f8f8;
    height: 46px;
    // margin-top: 10px;
    align-items: center;
    border: none;
    border-radius: 10px;
  }
}

.ant-select-item {
  font-size: 12px;
  line-height: 18px;
}

.ant-select-selection-selected-value {
  font-size: 12px;
}

.ant-picker-input {
  font-size: 12px;
}

.ant-select-tree {
  font-size: 12px;
}

.modal-dialog--header .modal__body {
  padding: 20px;
}

.ant-table-tbody > tr.ant-table-row-selected td {
  background: #e6f7ff;
}

i {
  &.far,
  &.fas,
  &.fal {
    font-size: 16px;
  }
}

span {
  &.lnr {
    font-size: 16px;
  }
}

.error {
  &.ant-select {
    .ant-select-selection {
      border: 1px solid red;
    }
  }
}

.rc-notification-notice-close,
.rc-notification-notice-close-x {
  color: white;
  opacity: 1;
}

.rc-notification {
  z-index: 1051;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #9e2743;
}

.ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  // border: 1px solid $color-primary;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
  &:hover {
    background-color: $color-primary;
    border-color: $color-primary !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  left: -1px;
  top: 2px;
}

.ant-checkbox-checked::after {
  border: 1px solid $color-primary !important;
}

.ant-checkbox-inner::after {
  left: 0%;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $color-primary !important;
}

.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  font-size: 16px;
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: $color-primary-lighter;
}

.ant-pagination-item-active {
  color: $color-additional-text;
  background-color: $color-secondary;
  margin-top: 15px;

  &:hover {
    a {
      color: inherit;
    }
  }
}

.ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #9e2743 !important;
}

.ant-table-thead {
  background: #fafbfe;
}

.ant-table-thead > tr > th {
  background: #fafbfe;
  border-bottom: 1px solid rgba(162, 162, 162, 0.65);
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(162, 162, 162, 0.65);
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(162, 162, 162, 0.65);
}

.ant-pagination-item {
  border: none;
  height: 46px;
  width: 46px;
  border-radius: 10px;
  font-size: 18px;
  margin: 0px 10px 0px 10px;
  &:hover {
    color: $color-primary;
    a {
      color: inherit;
    }
  }
}

.ant-pagination-item > a {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border: none;
  &:hover {
    color: $color-primary;
  }
}

.ant-pagination-prev,
.ant-pagination-jump-prev {
  margin-right: 10px;
  height: 46px;
}

.ant-pagination-next,
.ant-pagination-jump-next {
  margin-right: 0px;
  margin-left: 10px;
  height: 46px;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  padding-top: 15px;
}

.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  padding-top: 18px;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  padding-top: 18px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
  height: 46px;
  width: 46px;
  background-color: $color-primary-lighter;
  border-radius: 10px;
  font-size: 18px;
  svg {
    // margin-top: 10px;
    fill: $color-primary;
  }
}

.select-field .ant-select-selection {
  border: 1px solid red !important;
  border-radius: 4px !important;
  height: 46px;
}

.tree-select-field .ant-select-selection {
  border: 1px solid rgba(162, 162, 162, 0.65) !important;
  border-radius: 4px !important;
}

.ant-picker .ant-picker-input {
  // border: 0px solid #f8f8f8 !important;
  // background-color: #f8f8f8 !important;
  // padding-left: 37px !important;
  // border-radius: 10px;
  // height: 46px;
}

.ant-picker-input {
  // border: 1px solid rgba(162, 162, 162, 0.65) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  padding-left: 15px !important;
  input {
    font-size: 13px !important;
  }
  // padding-top: 5px;
  // margin: 10px 10px 10px 0px;
  // padding: 15px 20px !important;
}

.sidebar__link-icon {
  color: rgba(162, 162, 162, 0.65);
}

.sidebar__category-icon {
  color: rgba(162, 162, 162, 0.65);
  font-size: 12px !important;
  font-weight: 600 !important;
}

.ant-checkbox-inner {
  border: 5px solid $color-primary;
}

.modal .form input,
.modal .form textarea {
  border: none;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  margin-top: 10px;
}

.ant-input:focus {
  box-shadow: 0 0 0 3px #9e27431b !important;
  border: none;
  //background-color: #9e2743;
}

.ant-input:hover {
  border-color: #ddd;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #9e27431b !important;
}

.ant-radio-button-wrapper-checked {
  box-shadow: none !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: 0 0 0 3px #9e27431b !important;
}

.ant-select-arrow .anticon-down {
  color: #7a7a7a;
  font-size: 13px !important;
  font-weight: 900 !important;
}

.ant-picker-focused {
  box-shadow: 0 0 0 3px #9e27431b !important;
}

// .ant-select-arrow,
// .ant-picker-suffix,
.ant-input-search-icon {
  color: $color-primary;
  // left: 10px;
  // top: 32px;/
  // border-color: $color-primary;
}

.ant-picker {
  border: 0;
  border-radius: 10px;
  background-color: #f8f8f8;
  max-height: 46px;
}

.ant-picker-suffix {
  content: url(/assets/img/icon-calendar.svg);
  height: 20px;
  width: 20px;
}

.ant-picker-clear,
.ant-select-clear {
  background-color: #f8f8f8;
  // right: 10px;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #dceffc;
}

.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid rgba(162, 162, 162, 0.65);
}

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid rgba(162, 162, 162, 0.65);
}

.ant-select-search__field__wrap {
  display: none;
}

.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  background: none !important;
}

.ant-tooltip-inner {
  font-size: 10px;
  line-height: 12px;
  min-height: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  max-width: 117px;
  text-align: center;
  padding: 6px 4px;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: rgba(0, 0, 0, 0.6);
}

.ant-select-tree li {
  white-space: normal !important;
}

.ant-select-tree-dropdown {
  max-width: 420px !important;
}

.react-tel-input .country-list {
  width: 280px !important;
}

.ant-dropdown-menu-item {
  font-size: 12px;
  padding: 0 0 10px 0;
  &:last-child {
    padding: 0;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
  color: #9e2743;
}

.form-control:focus {
  box-shadow: none !important;
}

.container {
  max-width: 100% !important;
}

.form__form-group-warning {
  font-size: 10px;
  line-height: 13px;
  color: #fb9c4c;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.from-task,
.view-task {
  font-size: 13px;
}

.theme-light .form input,
.theme-light .form textarea {
  // border: 1px solid rgba(162, 162, 162, 0.65) !important;
  border-radius: 10px;
  // padding: 15px 20px !important;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.FormUser {
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
}

.ant-select-item {
  background-color: white !important;
}

.ant-select-item-option-selected {
  background-color: $color-primary-lighter !important;
}

.ant-select-item-option-active {
  background-color: $color-primary-lighter !important;
}

.ant-select-item-option-active:hover {
  background-color: rgb(248, 248, 248) !important;
}

.ant-select-item-option-selected .anticon-check {
  color: #9e2743;
}

.highlight {
  color: #000 !important;
}

@media (min-width: 768px) {
  .col-md-3 {
    padding-right: 8px;
  }
}


.ant-notification {
  z-index: 9999999;
}