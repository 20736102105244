.profile{
  .tabs .tabs__wrap{
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .tab-permissions-container .select-target_container .select_container.large {
    width: 48%;
  }

  .permissions-info {
    padding-top: 20px;
    max-height: 600px;
    overflow-y: auto;
  }
}