.button { 
    border: none;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 20px;
    width: 46px;
    height: 46px;
    border: 2px solid #F8F8F8;
    transition: all 0.2s linear;
}

.button img {
    width: 24px;
    height: 24px;
}

.button:hover {
    border-color: #9E2743;
    transform: translateY(-1px);
}
.button:active {
    transform: translateY(2px) scale(0.98);

}