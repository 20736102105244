.content-modal {
    & .subtitle {
        font-size: 18px;
        font-weight: 500;
        width: 100%;
    }

    & .txt {
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        display: block;
        margin-bottom: 15px;
    }
}