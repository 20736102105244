.tree-select-field {
    align-items: center;
    .ant-select-selection {
        margin-top: 10px;
        border-radius: 10px !important;
        padding: 0 13px 0 13px;
        border: none !important;
        height: 46px;
        background: rgba(248, 248, 248, 1) !important;
        .ant-select-selection__placeholder {
            font-size: 12px;
        }
    }
    .ant-select-search__field {
        border: none !important;
    }
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: lighten(#9e2743, 55%);
}

.ant-select-selection--single .ant-select-selection__rendered {
    width: 100%;
    height: 100%;
}

.ant-select-selection--single {
    height: 46px;
    margin-top: 10px;
}

.ant-select-selection__rendered {
    padding: 10px 0 10px 0;
}

// down icon field --> Access rights
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
    cursor: pointer;
    top: 22px;
}

.ant-select-arrow .ant-select-arrow-icon {
    position: absolute;
    color: rgba(127, 127, 127, 1);
    right: 0;
}

.ant-empty-image {
    img {
        width: auto;
    }
}

.label {
    font-size: 12px;
    color: rgba(127, 127, 127, 1);
}

.error {
    font-size: 12px;
    font-style: italic;
    color: #cc0000;
    line-height: 16px;
}