$primary-color: rgba(158, 39, 67, 1);
$search-bg-color: rgba(255, 255, 255, 1);
$placeholder-color: rgba(178, 179, 183, 1);
$bg-color-lighter: rgba(158, 39, 67, 0.1);

.attachments-container {
  display: flex;
  flex-direction: row;
}

.attachments-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: #f3f3f3;
  margin-left: 10px;

  img {
    width: 20px;
  }
  &:hover {
    cursor: pointer;
    filter: opacity(0.8) drop-shadow(0 0 0 $primary-color);
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .attachments-icon-button {
    background: transparent;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  .attachments-input {
    width: 0;
    height: 0;
  }
}

.indicator-wrapper {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $primary-color;

  .indicator-count {
    color: #ffffff;
    font-weight: 400;
    font-size: 10px;
  }
}

.chat-avatar-style {
  padding-right: 20px;
  font-size: 25px;
  .avatar-imageWrap {
    background-color: $bg-color-lighter;
    display: flex;
    justify-content: center;
    vertical-align: middle;

    .avatar-noImage {
      width: 25px;
      p {
        position: relative;
        top: 35%;
        color: $primary-color;
        text-align: center;

        font-size: 23px;
        line-height: 16px;
      }
    }
  }

  .online-indicator {
    width: 10px;
    height: 10px;
    background-color: #38b362;
    border-radius: 50%;
    border: 1.5px solid #ffffff;
    position: absolute;
    left: 85px;
    z-index: 1;
  }
}

.chat-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .chat-label {
    color: #393939;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;

    width: 110px;
    text-overflow: ellipsis;
    white-space: nowrap; /* paragraph to one line */
    overflow: hidden;
  }

  .last-message-info {
    // max-width: 175px;
    color: #7a7a7a;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    overflow-wrap: anywhere;
  }
}

.chat-card-rightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding-top: 3px;

  .chat-card-date {
    color: #7a7a7a;
  }

  .chat-card-indicator {
    align-self: center;
  }
}

.select-chat-btn {
  button {
    width: 346px;
    height: 35px;
    border: none;
    border-radius: 50px;
    background-color: $bg-color-lighter;
  }

  p {
    color: $primary-color;
    font-size: 16px;
    line-height: 14px;
  }
}

.pinned-msg-btn-container {
  margin-left: 20px;

  &:hover {
    cursor: pointer;
  }
}

.forward-btn-container {
  width: 36px;
  height: 36px;
  margin-left: 4px;
  align-self: flex-end;
  border-radius: 50%;
  display: none;

  .chat-forward-button {
    background-color: #f8f8f8;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.chat-down-container {
  position: absolute;
  right: 35px;
  bottom: 100px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  .chat-down-button {
    background-color: #f5e9ec;
    border: 1px solid #d9d9d9;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;

    img {
      width: 15px;
      filter: invert(15%) sepia(92%) saturate(2220%) hue-rotate(328deg)
        brightness(98%) contrast(85%);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.search-input {
  background: $search-bg-color;
  border-radius: 10px;
  align-items: center;
  padding-left: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 24px;
  }

  i {
    font-size: 25px;
    color: rgba(198, 198, 198, 1);
  }

  .clear {
    font-size: 15px;
    padding-right: 5px;
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 15px;
    width: fit-content;
    color: rgba(198, 198, 198, 1);
    :hover {
      color: #949292;
    }
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    background: none;
    font-size: 14px;
    padding: 0 13px 0 13px;
    -webkit-appearance: none;

    &::placeholder {
      color: $placeholder-color;
    }

    &:focus {
      outline: none !important;
    }
  }
}

.chat-header-container {
  .chat-header-label {
    color: #393939;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }

  .chat-header-stats {
    color: #7a7a7a;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }
}

.pinned-limit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 20px;
  }
}

.error-tooltip {
  color: red;
}

.chat-selected-messages-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 34px;
  font-size: 14px;
  line-height: 16px;
}

.selected-messages-cancel-btn {
  color: #9e2743;

  &:hover {
    cursor: pointer;
  }
}

.selected-messages-count-text {
  font-weight: 500;
}

.selected-messages-action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  border-radius: 50%;
  color: #9e2743;

  img {
    height: 15px;
    margin-bottom: 2px;
  }

  &:hover {
    cursor: pointer;
  }
}
