$bg-color: rgba(248, 248, 248, 1);
$color-label: rgba(127, 127, 127, 1);
$placeholder-color: rgba(198, 198, 198, 1);
$border-radius: 10px;

.input-field {
  width: 100%;
  input {
    border: none !important;
    border-radius: $border-radius !important;

    &::placeholder {
      color: $placeholder-color;
    }
  }
}

.text-field {
  textarea {
    border: none !important;
    border-radius: $border-radius !important;
    font-size: 13px;
  }
}

.input-password-field {
  width: 100%;
  margin-bottom: 20px;
  input {
    border: none !important;
    border-radius: $border-radius !important;

    &::placeholder {
      color: $placeholder-color;
      padding-right: 10px;
      font-size: 12px;
    }
  }
}

.layout--collapse + .container__wrap {
  .tabs__wrap {
    .input-mask-field {
      width: 100%;
      margin-bottom: 20px;

      .input-label {
        font-size: 12px;
        line-height: 1.7;
        color: $color-label !important;
      }

      .input-container {
        width: 100%;
        margin: 10px 10px 10px 0px;
      }

      input {
        border: none !important;
        border-radius: $border-radius !important;
        transition: padding-left 0.3s linear, padding-right 0.3s linear;
        font-size: 13px !important;
        padding-left: 15px;
        padding-right: 0px;

        &::placeholder {
          color: $placeholder-color;
        }
      }
    }
  }
}

.input-mask-field {
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  .contact-copy {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 34px;
    right: 10px;
    cursor: pointer;
    z-index: 99;
  }

  .input-label {
    font-size: 12px;
    // line-height: 1.7;
    margin-bottom: 5px;
    color: $color-label !important;
  }

  .input-container {
    width: 100%;
    margin: 10px 10px 10px 0px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    padding: 0px 10px;
    border-radius: 10px !important;
    transition: all 0.2s linear;
    &:focus-within {
      box-shadow: 0 0 0 3px rgba(158, 39, 67, 0.1058823529) !important;
    }
    .contact-copy {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  input {
    border: none !important;
    border-radius: $border-radius !important;
    font-size: 13px !important;
    padding-left: 5px;
    padding-right: 0px;
    transition: padding-left 0.3s linear, padding-right 0.3s linear;

    &:focus {
      box-shadow: none !important;
    }

    &::placeholder {
      color: $placeholder-color;
    }
  }
}

.input-profile-field {
  width: 100%;
  // margin-bottom: 20px;

  .input-label {
    font-size: 12px;
    // line-height: 1.7;
    margin-bottom: 10px;
    color: $color-label !important;
  }

  .input-container {
    width: 100%;
    margin: 10px 10px 10px 0px;
  }

  input {
    border: none !important;
    border-radius: $border-radius !important;
    font-size: 13px !important;
    padding-left: 5px;
    padding-right: 0px;
    transition: padding-left 0.3s linear, padding-right 0.3s linear;

    &::placeholder {
      color: $placeholder-color;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  .ant-select-selection-search-input {
    height: 100%;
    font-size: 13px;
    color: #3b3b3b;
  }
}

.ant-select {
  .ant-select-selection--multiple,
  .ant-select-selection-search-input,
  .ant-select-selection__rendered {
    height: 100%;
    font-size: 13px;
    color: #3b3b3b;
  }
}

.select-multiply-field {
  .field-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-top: 10px;

    @extend .select-field_custom;

    .ant-select-selection--multiple .ant-select-selection__choice {
      display: flex;
      align-items: center;
      border: none !important;
      background: rgba(235, 235, 235, 1);
      margin: 5px;
      border-radius: 5px;

      & > div {
        background: rgba(235, 235, 235, 1);
      }
      & i {
        vertical-align: 0;
      }
    }

    .open-select-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      width: 55px;
      height: 30px;
      border-radius: 6px;
      border: none;
      background: rgba(235, 235, 235, 1);
      .icon {
        color: rgba(127, 127, 127, 1);
        transform: scale(1.9, 1);
      }
    }
    .ant-select-selector {
      height: auto;
    }
    .select {
      div {
        overflow: auto;
      }
    }
  }
}

.tree-select-field {
  .ant-select-selection-item {
    font-size: 13px;
    color: #3b3b3b;
  }
}

.select-field_custom {
  .select {
    width: 100%;

    .ant-select-selector {
      min-height: 46px;
    }

    .ant-select-selector .ant-select-selection-search-input {
      height: 100%;
    }

    .ant-select-selection-item {
      font-size: 13px;
      color: #3b3b3b;
    }

    .ant-select-selection-item-content {
      font-size: 13px;
      color: #3b3b3b;
    }

    .ant-select-selection-selected-value {
      // padding-left: 13px;
    }
    div {
      background: $bg-color;
      overflow: hidden;
      border: none !important;
      border-radius: $border-radius !important;
      height: auto;
      & > div {
        // padding: 0 13px 0 13px;
      }

      .ant-select-selection-placeholder {
        color: #bfbfbf;
      }

      span /* DOWN ARROW */ {
        // align-items: flex-end;
        color: rgba(122, 122, 122, 1);
        .anticon {
          vertical-align: middle;
        }
      }
    }
  }
}

.search-input-field {
  background: $bg-color;
  border-radius: $border-radius;
  align-items: center;
  padding-left: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    font-size: 25px;
    color: rgba(198, 198, 198, 1);
  }

  .clear {
    font-size: 15px;
    padding-right: 5px;
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 15px;
    width: fit-content;
    color: rgba(198, 198, 198, 1);
    :hover {
      color: #949292;
    }
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    background: none;
    font-size: 14px;
    padding: 0 13px 0 13px;
    -webkit-appearance: none;

    &::placeholder {
      color: $placeholder-color;
    }

    &:focus {
      outline: none !important;
    }
  }
}

.input-checkbox {
  display: flex;
  align-items: center;
  position: relative;

  input[type="checkbox"] {
    border-radius: 2px;
    -webkit-appearance: none;
    background: rgba(158, 39, 67, 0.1);
    margin-right: 5px;

    &:checked {
      background: rgba(158, 39, 67, 1);
    }
  }

  .check-mark {
    position: absolute;
  }
}

// SHARED STYLES ---
.label {
  font-size: 12px;
  color: rgba(127, 127, 127, 1);
}

.error {
  font-size: 12px;
  font-style: italic;
  color: #cc0000;
}

.contact-field {
  width: 100%;

  .contact-label {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    color: rgba(127, 127, 127, 1);
  }
  .value-container {
    display: flex;
    background-color: $bg-color;
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 16px;

    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 0px 20px;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .images {
      display: flex;
      flex-direction: row;
    }
  }

  .contact-copy {
    margin-left: 15px;
    cursor: pointer;
    position: relative;
    z-index: 99;
    &:hover {
      opacity: 0.5;
    }
  }
}

.functional-email-field {
  .functional-email-label {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 4px;
    color: rgba(127, 127, 127, 1);
  }

  .value-container {
    display: flex;
    background-color: $bg-color;
    height: 46px;
    border-radius: 10px;
    font-size: 13px;
    line-height: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 15px;

    &:hover {
      cursor: pointer;
    }

    .contact-copy {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
}
