


.secret-users-table .table-grid_custom .rdg {
    height: 60vh;
}

.secret-users-table {
    .icon-trash {
        width: 20px;
    }
}