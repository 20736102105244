.buttons-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1700px) {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .count-label {
    font-size: 16px;
    color: #7f7f7f;
    margin-right: 20px;
  }

  .buttons-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .btn-container {
    margin-right: 20px;
    :hover {
      color: #7f7f7f;
    }
  }

  .btn-container-active {
    margin-right: 20px;
    :hover {
      color: #9e2743;
    }
  }

  .btn-active {
    background-color: #f9e5ea;
    color: #9e2743;
    :hover {
      color: #9e2743;
    }
  }

  .active-icon {
    filter: invert(20%) sepia(26%) saturate(6093%) hue-rotate(325deg)
      brightness(68%) contrast(88%);
  }
}
