.callstats-section {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 20px;
}

.callstats-title {
    font-size: 20px;
}

.callstats-item {
    margin: 0;
}

.callstats-detailed-item {
    background-color: rgba(0,0,0,.05);
    padding: 10px;
}