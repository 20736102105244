.ant-calendar {
    top: 50px;
    border-radius: 8px;
    width: 248px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    background-color: #9e2743;
    border-radius: 100px;
    border: none;
    color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #9e2743;
    border-radius: 100px;
    border-style: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #9e2743;
}

.ant-calendar-input-wrap {
    display: none;
}

.ant-calendar-column-header .ant-calendar-column-header-inner {
    color: rgba(127, 127, 127, 1);
}

.modal .form input,
.modal .form textarea {
    border-radius: 10px;
}

.anticon[tabindex] {
    display: none;
}

.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
    display: none;
}

.ant-calendar-header {
    border-bottom: none;
}

.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after,
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
    border-color: rgba(59, 59, 59, 1);
}

.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
    -webkit-transform: rotate(-45deg) scale(1.3);
}

.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
    transform: rotate(135deg) scale(1.3);
}

.ant-calendar-selected-day .ant-calendar-date {
    width: 35px;
    height: 35px;
    background: rgba(158, 39, 67, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    border: none;
}

.ant-calendar-date:hover {
    background: none !important;
    color: rgba(158, 39, 67, 1);
}

.ant-calendar-today .ant-calendar-date {
    border-radius: 50%;
    border-color: rgba(158, 39, 67, 1);
    // color: rgba(158, 39, 67, 1);
    font-weight: normal;
}

.ant-picker-suffix {
    position: absolute;
    left: 0;
    top: 11px;
    // right: 18px;
    // top: 30px;
    font-size: 20px;
    transform: scale(1.5);
    // filter: invert(50%) sepia(23%) saturate(4613%) hue-rotate(329deg)
    //   brightness(60%) contrast(96%);
}

.ant-picker .ant-picker-input {
    padding-left: 10px !important;
}

.profile__card .ant-picker .ant-picker-input {
    padding-left: 30px !important;
}

.count-task-user .ant-picker .ant-picker-input {
    padding-left: 35px !important;
    padding-top: 4px;
}

.sidebar__wrapper .ant-picker .ant-picker-input {
    padding-left: 10px !important;
    padding-top: 4px;
    height: 46px;
    position: relative;
    .ant-picker-suffix {
        right: 0;
        left: unset;
    }
}