.add-group-field_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(158, 39, 67, 0.1);

  div {
    width: 50%;
  }
}

.select-factory_container {
  display: flex;
  justify-content: space-between;
  margin-top: 1px;
  margin-bottom: 8px;

  .select {
    width: 230px;
  }
}

.multi-select {
  margin-bottom: 15px;
}

.ant-btn-lg {
  height: 46px;
  border-radius: 10px;
}

.btn-save {
  width: 150px;
  align-self: "left";
  margin-top: 15px;

  //   .ant-btn-lg {
  //     height: 46px;
  //     border-radius: 10px;
  //   }
}

.form__form-group .label {
  display: block;
  margin-bottom: 10px;
}
