$primary-color: rgba(158, 39, 67, 1);
$search-bg-color: rgba(255, 255, 255, 1);
$placeholder-color: rgba(178, 179, 183, 1);
$bg-color-lighter: rgba(158, 39, 67, 0.1);

/* CHAT ITEM STYLES */

.context-menu-chat-item {
  padding: 15px;
  border-radius: 20px;

  .ant-dropdown-menu-title-content {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #7f7f7f;
  }
}

.chat-item-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.chat-item-container.myContainer {
  justify-content: flex-end;
}

.chat-item-video-container.myContainer {
  justify-content: flex-end;
}

.chat-item-container.mySelectableContainer {
  justify-content: space-between;
}

.chat-item-video-container.mySelectableContainer {
  justify-content: space-between;
}

.chat-item-container:hover > div {
  display: block;
}

.chat-item-video-container:hover > div {
  display: block;
}

.chat-avatar-container {
  margin-right: 20px;
  align-self: flex-start;

  .avatar-imageWrap {
    background-color: $bg-color-lighter;
    cursor: pointer;
    display: flex;
    justify-content: center;
    vertical-align: middle;

    .avatar-noImage {
      width: 25px;

      p {
        position: relative;
        top: 35%;
        color: $primary-color;
        text-align: center;

        font-size: 18px;
        line-height: 16px;
      }
    }
  }
}

.item-content {
  max-width: 75%;
}

.item-content.withBorder {
  padding-left: 15px;
  border-left: 1px solid #c6c6c6;
  border-width: 1px;
}

.name {
  color: #9b9b9b;
  padding-left: 0px;
  padding-right: 0px;

  span {
    font-weight: 500;
  }
}

/* PINNED MESSAGE STYLES */

.pinned-message-container {
  position: absolute;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 80px;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  padding: 20px 20px 20px 20px;
  z-index: 2;

  .pinned-message-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    padding-left: 5px;
  }

  .pinned-message-content {
    display: flex;
    flex-direction: row;
    &:hover {
      cursor: pointer;
    }
  }

  .pinned-message-media {
    padding: 0px 10px 0px 10px;
  }

  .pinned-message-textblock {
    display: flex;
    align-items: center;
    min-width: 400px;

    span {
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .pinned-message-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 25%;
  }
}

/* REPLY BAR CONTAINER STYLES */

.reply-bar-container {
  position: relative;
  margin-top: -59px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(219, 219, 219, 0.5);
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  height: 60px;
  background-color: white;
  z-index: 10;

  .reply-main-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    border-left: 2px solid $primary-color;
    margin-left: 10px;

    .reply-media-preview {
      padding: 0px 10px 0px 10px;
    }

    .reply-text-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      // height: 1em;

      span {
        font-size: 12px;
        line-height: 20px;
        color: #7f7f7f;
        font-weight: 500;
      }

      .collapsed {
        white-space: nowrap;
        overflow: hidden;
        height: 1.5em;
        text-overflow: ellipsis;
        max-width: 90%;
      }
    }
  }

  .reply-close-section {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.replied-message-container {
  border-left: 2px solid $primary-color;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-left: 9px;
  margin-bottom: 10px;

  .replied-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5px;
    width: 100%;
  }

  .user-name {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #7f7f7f;
  }

  .replied-message-textblock {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
}

.reply-message_file-icon {
  width: 30px;
  height: 30px;
}

.forwarded-row-container {
  margin-bottom: 10px;

  .forwarded-row {
    display: flex;
    flex-direction: row;
  }
  .forwarded {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #7f7f7f;
  }

  .link {
    color: #378bfb;
    font-weight: 500;
  }
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mainContentRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  .message-container {
    font-size: 12px;
    padding-right: 20px;

    width: 100%;
  }
}

.ticksInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;

  span {
    font-size: 10px;
    color: #7a7a7a; // timer color
  }
}

.right {
  background-color: rgba(158, 39, 67, 0.1);
  border-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 5px;
  padding: 15px 20px 15px 20px;
}

.left {
  background-color: #f8f8f8;
  border-radius: 25px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 25px;
  padding: 15px 20px 15px 20px;

  .headerRow .name {
    margin-right: 20px;
  }
}

.tick {
  margin-left: 10px;
  width: 15px;
}

.tick.ticksMy {
  filter: invert(26%) sepia(19%) saturate(5162%) hue-rotate(316deg)
    brightness(83%) contrast(97%);
}

.tick.ticks {
  filter: invert(52%) sepia(1%) saturate(0%) hue-rotate(55deg) brightness(91%)
    contrast(92%);
}

.pin {
  margin-left: 10px;
}

.forwarded {
  font-size: 10px;
  margin-bottom: 5px;
  color: red;
}

.loader-styles {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CHAT ITEMS MESSAGES STYLES */
.infinite-scroll-messages-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
}

.infinite-scroll-messages-container::-webkit-scrollbar {
  display: none;
}

/* CHAT ITEM MESSAGE STYLES */

.chat-item-message-container {
  .message-container {
    font-size: 12px;

    width: 100%;
    overflow-wrap: anywhere;

    a {
      color: #378bfb;
      font-size: 12px;
    }
  }
}

/* CHAT ITEM IMAGES STYLES */

.chat-item-image-container {
  img {
    border-radius: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 25px;
    margin-bottom: 10px;
  }

  img.myImage {
    border-radius: 25px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 25px;
    margin-bottom: 10px;
  }
}

/* CHAT ITEM VIDEO STYLES */

.chat-item-video-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .with-border {
    padding-left: 15px;
    border-left: 1px solid #c6c6c6;
    border-width: 1px;
  }

  .video-authorContainer {
    position: "absolute";
    top: 0;
    left: 0;
    margin-bottom: 5px;
    margin-top: 10px;
    margin-left: 20px;
    z-index: 10;

    .name {
      color: #7a7a7a;
    }
  }

  .video-footerRow {
    bottom: 0;
    left: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 20px;

    span {
      font-size: 10px;
      color: #7a7a7a;
    }
  }

  .player-wrapper {
    width: 200px; // Reset width
    height: auto; // Reset height
    padding: 1px 0px 1px 0px;

    .with-border {
      padding-left: 15px;
      border-left: 1px solid #c6c6c6;
    }

    .replied-message-container {
      margin-top: 15px;
      margin-left: 20px;
    }
  }

  .react-player {
    // padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }

  .react-player > video {
    background-color: $bg-color-lighter;
  }
}

/* CHAT ITEM FILE STYLES */
.chat-item-file-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  .file-icon-container {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #7f7f7f;
  }

  .file-item-icon {
    width: 100%;
  }

  .file-item-text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    overflow: hidden;

    p {
      font-size: 12px;
      line-height: 20px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.right .chat-item-file-container {
  margin-bottom: 10px;
}

.chat-item-video-container.my-video-container {
  justify-content: flex-end;
}

.player-wrapper.left-video {
  background-color: #f8f8f8;
  border-radius: 25px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 25px;
}

.player-wrapper.right-video {
  border-radius: 25px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background-color: rgba(158, 39, 67, 0.1);
}

.playBtnWrapper {
  position: absolute;
  padding: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .playBtn {
    background-color: #646868f2;
    padding: 15px;
    border-radius: 50px;
  }
}

/* CHAT ITEM AUDIO MESSAGE */

.player {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .audio-message {
    user-select: none;
  }

  .audio-controls {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .player__button {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 50%;

    margin-right: 20px;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }

    img {
      width: 14px;
      height: 14px;
    }

    svg {
      font-size: 4em;
      color: #9e2743;
    }
  }

  .player__button.sound-icon {
    background-color: rgba(158, 39, 67, 0.1);
  }

  .player__button.my-sound-icon {
    background-color: white;
  }

  .bar {
    user-select: none;
    width: 150px;
    display: flex;
    align-items: center;

    .bar__progress {
      flex: 1;
      border-radius: 5px;
      height: 2px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 12px;
        width: 12px;
        border: 2px solid #9e2743;
        border-radius: 50%;
        background-color: white;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}

/* CHAT ITEM SYSTEM STYLES */

.system-text {
  font-size: 12px;
  line-height: 12px;
  color: #7a7a7a;
  margin-bottom: 10px;
  text-align: center;
}

/* CHAT HEADER CONTAINER */

.chat-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  padding: 15px 20px 15px 20px;

  .chat-header-info {
    display: flex;
    flex-direction: row;
  }

  .chat-header-settings {
    display: flex;
    flex-direction: row;

    .chat-unread-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      border-radius: 50%;
      background-color: rgba(158, 39, 67, 0.1);

      img {
        width: 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .chat-settings {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      border-radius: 50%;
      background-color: rgba(158, 39, 67, 0.1);
      margin-left: 10px;

      img {
        width: 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

/* CHAT BAR CONTAINER */

.chat-bar-container {
  margin-top: auto;
  min-height: 80px;
  background-color: #ffffff;
  border-top: 1px solid rgba(219, 219, 219, 0.5);
  border-radius: 0px 0px 20px 20px;
  z-index: 2;

  .smile-component {
    position: absolute;
    right: 20px;
    // bottom: 85px;
  }

  .chat-bar-input-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .chat-bar-left {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding: 20px 20px 20px 20px;
  }

  .chat-bar-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 92%;
  }

  .chat-bar-settings {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    background-color: rgba(158, 39, 67, 0.1);

    img {
      width: 15px;
    }

    .chat-bar_send-icon {
      width: 22px;
    }

    &:hover {
      cursor: pointer;
    }

    .attachments-icon-button {
      background: transparent;
      border: none;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .chat-bar-right {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding: 20px 20px 20px 20px;
  }

  .chat-bar-smiles {
    display: flex;
    align-items: center;
    min-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    background-color: #ffffff;

    img {
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }

    .smile-icon-button {
      background: transparent;
      border: none;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .chat-bar-sending {
    display: flex;
    align-items: center;
    min-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    background-color: rgba(158, 39, 67, 0.1);

    img {
      width: 15px;
    }

    &:hover {
      cursor: pointer;
    }

    .send-icon-button {
      background: transparent;
      border: none;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .message-input {
    min-width: 60%;
    word-break: break-all;
  }

  .message-input__suggestions {
    top: -230px !important;
    bottom: 75px !important;
    // bottom: inherit !important;
    // bottom: 50%;
    left: -75px !important;
    // top: unset !important;

    // height: 200px;
    // min-height: 110px;
    max-height: 205px;
    overflow-y: scroll;
    // width: 100%;
    width: 260px;
    border-radius: 20px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .message-input__input {
    font-size: 13px;
    line-height: 22px;
    border: none;
    overflow: auto !important;
    height: 66px;
    max-height: 106px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::placeholder {
      color: #7a7a7a;
    }

    &:focus {
      outline: none !important;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  .message-input__highlighter {
    font-size: 13px;

    box-sizing: border-box;
    overflow: hidden;
    max-height: 106px;
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
      -1px -1px 1px white;
    font-size: 13px;
    text-decoration: underline;
    pointer-events: none;
  }

  .suggestion-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;

    &:hover {
      cursor: pointer;
      background-color: $bg-color-lighter;
    }
    .user-name {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: "#444";
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.message-checkbox {
  align-self: center;
  margin-right: 19px;

  &:hover {
    .ant-checkbox-inner {
      border: 1px solid #e2e8f0;
      background-color: #f8f8f8;
    }
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #e2e8f0 !important;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 1px solid #e2e8f0;
    background-color: #f8f8f8;

    :hover {
      border: 1px solid #e2e8f0;
      background-color: #f8f8f8;
    }

    ::after {
      border-radius: 50% !important;
    }
  }

  .ant-checkbox-checked {
    & .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #9e2743 !important;
    }

    &::after {
      border: none !important;
    }

    ::after {
      left: 4.7px;
      top: 7.8px;
    }
  }
}

.custom-info-message {
  .ant-message-notice-content {
    border-radius: 4px;
    filter: drop-shadow(rgb(224, 224, 224) 2px 2px 4px);
  }
}
