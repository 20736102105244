$color-primary: #9e2743;

.FilterTask {
  .sidebar__submenu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .form__form-group {
    margin-bottom: 2px !important;
  }
}

.filter-task {
  margin-left: 15px;
  width: 175px;
  .filter-icon {
    display: none;
  }
  .filter {
    // display: none;
    .form__form-group {
      margin-bottom: 10px;
    }
  }

  .profile__data_card {
    margin-bottom: 10px;
  }

  .profile__data-tooltip {
    margin-bottom: 3px;
  }

  .form__form-group .label {
    margin-bottom: 3px;
  }
}

.sidebar--collapse {
  .filter-task {
    .filter {
      // display: none;
    }
    .filter-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
