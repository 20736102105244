$primary-color: #9e2743;
.taxonomy__avatar {
  height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-width: 1px solid;
  cursor: pointer;
  background-color: #E6E7EB;

  img {
    height: 100px;
    width: 100px;
    object-fit: fill;
  }

  .default-img {
    width: 90px;
    height: 90px;
  }

  .avatar-noImage {
    align-self: center;
    p {
      position: relative;
      color: $primary-color;
      text-align: center;
      font-size: 30px;
      line-height: 30px;
    }
  }
}