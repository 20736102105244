// #fafbfe
$danger: #9e2743;
.table-grid_custom {
  width: 100%;
  height: 100%;
  .ant-btn-primary {
    background-color: #9e2743;
    border-color: #9e2743;
  }
  .table-spinner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  }
  .rdg {
    overflow-y: auto;
    border: none;
    height: 40vh;
  }
  @media screen and (min-width: 1440px) {
    .rdg {
      overflow-y: auto;
      border: none;
      height: 50vh;
    }
  }
  // .rgd-cell-resizable {
  //   height: 100px !important;
  // }
  .rdg-cell-frozen {
    position: absolute;
    z-index: 1;
  }
  .rgb-resetAlign {
    text-align: start;
    padding-left: 30px;
  }
  .rdg-header-row {
    justify-content: center;
    border-bottom: 1px solid #eaeaea;
    .rdg-cell {
      background: #fff;
      // border-top: 1px solid #eaeaea;
      // border-bottom: 1px solid #eaeaea;
      border-bottom: none;
      box-shadow: none;
      height: 120px;
      line-height: 55px;
      text-align: left;
    }
    .rdg-header-sort-name {
      font-weight: 500;
      font-size: 16px;
      color: rgba(59, 59, 59, 1);
    }
    &:nth-child(1) {
      .rdg-cell {
        border-top: 1px solid #ddd;
        // height: 55px;
      }
    }
    &:nth-child(2) {
      .rdg-cell {
        border-right: none;
        // height: 55px;
      }
    }
    .rdg-cell:last-child {
      border-right: none;
      // height: 55px;
    }
  }
  .rdg-row-custom-class_IP {
    height: 55px;
    &:last-child {
      .rdg-cell {
        border-bottom: none;
      }
    }
    .rdg-cell {
      box-shadow: none;
      font-weight: 400;
      font-size: 14px;
      border-top: none;
      border-bottom: 1px solid #eaeaea;
      color: #3b3b3b;
      // &:first-child {
      //   border-top: none;
      // }
      &:last-child {
        // padding-left: 30px;
        border-right: none;
      }
    }
    .fa-star {
      color: #f6da6e;
      cursor: pointer;
    }
    .row-selected {
      background-color: #f9e5ea;
    }
    &:hover {
      background-color: #f9e5ea;
    }
  }
  .rdg-row-custom-class {
    height: 55px;
    max-width: 100%;
    text-align: center;
    &:last-child {
      .rdg-cell {
        border-bottom: none;
      }
    }
    .rdg-cell {
      box-shadow: none;
      font-weight: 400;
      font-size: 14px;
      border-right: none;
      &:last-child {
        border-right: none;
      }
    }
    .fa-star {
      color: #f6da6e;
      cursor: pointer;
    }
    &.row-selected {
      background-color: #f9e5ea;
    }
    &:hover {
      background-color: #f9e5ea;
    }
    &.outdate {
      color: #9e2743;
    }
    &.doneOverdue {
      color: black;
    }
    &.inactive {
      color: #7f7f7f;
    }
  }
  .secondary-text {
    color: #7f7f7f;
  }
  .full-name-blocked {
    font-size: 16px;
    font-weight: 500;
    color: $danger;
  }
  .blocked-text {
    color: $danger;
  }
  .blocked-position {
    font-size: 14px;
    font-weight: 500;
    color: $danger;
    text-align: start;
  }
  .rdg-row-custom-class:first-child {
    border-top: none;
  }
  .rdg-row-custom-class:last-child {
    border-bottom: none;
  }
  .table-navigate-action {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .table-navigate-action-r {
      // margin-top: 15px;
      .ant-select {
        display: none;
      }
      .ant-dropdown-trigger {
        background-color: #f8f8f8;
        border-radius: 10px;
        border: none;
        height: 46px;
        width: 46px;
        // &:hover {
        //   color: #9e2743;
        // }
      }
      .ant-btn + .ant-btn {
        background-color: #f8f8f8;
        border-radius: 10px;
        border: none;
        margin-left: 20px;
        height: 46px;
        width: 46px;
        // &:hover {
        //   color: #9e2743;
        // }
      }
    }
  }
  .ant-btn:hover .action_bottom_bar-icon {
    path {
      stroke: #9e2743;
    }
  }
  .react-grid-Cell:focus {
    outline: none;
  }
  .react-grid-Main {
    background-color: #fff;
    color: inherit;
    padding: 0;
    outline: 1px solid #e7eaec;
    clear: both;
  }
  .react-grid-Header {
    box-shadow: 0 0 4px 0 #ddd;
    background: #f9f9f9;
  }
  .react-grid-HeaderCell {
    background: #f9f9f9;
    padding: 8px;
    font-weight: 700;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  .react-grid-Cell {
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #ddd;
  }
  .rdg-selected {
    // border: none !important;
    background-color: #9e2743 !important;
    border-color: #9e2743;
  }
  .rdg-row-selected {
    background-color: rgba(158, 39, 67, 0.1);
  }
  .rdg-checkbox-label {
    margin-bottom: 0;
  }
  // .rdg-checkbox-input {
  // }
  .rdg-checkbox {
    // border-radius: 50%;
    border: 0px solid #ddd;
    background-color: rgba(158, 39, 67, 0.1);
    padding: 0;
    transition: all 0.3s;
    &:hover {
      border-color: #9e2743;
    }
  }
  .rdg-checkbox-input:checked + .rdg-checkbox {
    background-color: #9e2743 !important;
    border-color: #9e2743;
    box-shadow: none;
  }
  .date-picker-table .ant-picker-suffix {
    width: 15px;
    height: 15px;
    right: 0px;
    left: unset;
    top: 2px;
  }
  .date-picker-table .ant-calendar-picker-clear {
    font-size: 18px;
    width: 20px;
    height: 20px;
  }
  .date-picker-table {
    height: 32px;
    margin-top: 12px;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    background-color: #ffffff;
    padding-left: 0;
    width: 100%;
    .anticon[tabindex] {
      display: initial;
      top: 14px;
      right: 6px;
    }
  }
  .ant-select.ant-select-single {
    height: 32px;
    // margin-top: 12px;
    border: 1px solid #d9d9d9;
    .ant-select-selector {
      height: auto;
      background-color: #ffffff;
      border-radius: 0;
    }
  }
  .ant-select-selection__rendered {
    padding: 0;
  }
  .pressable-text {
    &:hover {
      cursor: pointer;
    }
  }
  .r1otpg64700-canary49 {
    contain: none;
  }
  .c1wupbe700-canary49 {
    contain: layout;
  }
}

.date-picker-dropdown-dateOfBirth .ant-picker-header .ant-picker-year-btn {
  display: none;
}

.table-grid_custom .rdg-cell-frozen {
  position: static;
  z-index: 1;
}

.rdg-checkbox-input:checked + .rdg-checkbox::before {
  box-shadow: none;
  position: absolute;
  top: 0%;
  right: 35%;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  color: #fff;
  font-size: 10px;
}

.collums-row-sort {
  max-width: 185px;
  display: flex;
  justify-content: space-between;
  .checkbox > span > input {
    border-radius: 2px !important;
    background-color: blue !important;
  }
  .sort-icons {
    display: flex;
    justify-content: space-between;
    min-width: 41px;
    margin-left: 4px;
    .btn-icon + .btn-icon {
      margin-left: 5px;
    }
    .btn-icon {
      background: #f5f5f5;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(162, 162, 162, 0.25);
      i {
        font-size: 12px;
        color: black;
      }
    }
  }
}

.context-menu {
  padding: 15px;
  .ant-dropdown-menu-title-content {
    font-size: 14px;
  }
}

.selected-rows-menu-btn.context {
  background-color: #ffffff;
  height: 35px;
  width: 40px;
  border: none;
  margin-right: 10px;
  .note-pencil-icon {
    width: 25px;
  }
  .basket-icon {
    width: 30px;
    // margin-left: -2px;
  }
  .double-check-icon {
    width: 26px;
    height: 24px;
  }
  .reassign-icon {
    height: 22px;
  }
  .double-paper-icon {
    height: 23px;
  }
  .printer-icon {
    height: 22px;
  }
  .hard-delete-icon {
    height: 22px;
  }
  .icon-user-pen {
  }
  &.icon-add-user {
    width: 22px;
    height: 22px;
    margin-left: 8px;
    svg {
      width: 21px;
      height: 21px;
      color: #8f8c8c;
    }
  }
  .user-unlock-icon {
    width: 30px;
    height: 20px;
  }
}

.context-menu-icon {
  height: 35px;
  width: 40px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .lock-icon,
  .unlock-icon {
    svg {
      width: 22px;
      height: 22px;
      color: #929090;
    }
  }
  .white-list-icon {
    svg {
      width: 21px;
      height: 21px;
      color: #929090;
    }
  }
}
