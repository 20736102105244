@import "~react-datepicker/dist/react-datepicker.css";
@import "~rc-time-picker/assets/index.css";
.form {
  display: flex;
  flex-wrap: wrap;
  // padding-bottom: 20px;
  .input-label,
  .profile__data-tooltip,
  .label {
    margin-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px 15px 10px 5px;
    // margin-left: 20px;
    font-size: 16px;
    line-height: 16px;
    height: 46px;
    transition: border 0.3s;
    background: $color-secondary;
    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }
    @include themify($themes) {
      border: 1px solid $color-secondary;
      color: themed("colorTextPrimary");
    }
    &[disabled] {
      @include themify($themes) {
        background: themed("colorBackgroundBody");
      }
      &:focus,
      &:active {
        @include themify($themes) {
          border-color: $color-primary;
        }
      }
    }
    &:focus,
    &:active {
      outline: none;
      // border-color: $color-primary;
    }
  }
  textarea {
    min-height: 85px;
  }
  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }
    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }
    .form__form-group-field-remember {
      width: calc(50% - 80px);
      padding-left: 10px;
      margin-left: 80px;
      border-width: 1px;
    }
    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      font-size: 12px;
      line-height: 12px;
      margin: auto 0;
      & + .form__form-group-field {
        margin-left: 0;
      }
    }
    .form__form-group-description {
      margin-left: 90px;
    }
    .form__button-toolbar {
      margin-left: 0;
    }
    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;
      .form__form-group-error {
        top: -28px;
      }
    }
    @media screen and (min-width: 480px) {
      .form__form-group-label {
        width: 120px;
      }
      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }
      .form__form-group-description,
      .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }
  &.form--preview {
    display: flex;
    & > div:nth-child(2) {
      margin-right: 50px;
    }
    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }
    p {
      margin-bottom: 10px;
    }
  }
  &.form--justify {
    display: flex;
    flex-wrap: wrap;
    .form__form-group {
      width: 33.3333%;
    }
    .form__button-toolbar {
      width: 100%;
    }
  }
}

.form__add-IP {
  margin-top: 0px;
  margin-bottom: 20px;
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;
  &:first-child {
    margin-right: 30px;
  }
  .form__button-toolbar {
    float: right;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
}

.form__form-group {
  // margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form__form-group-auth {
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  .input-mask-field {
    margin-bottom: 0;
  }

  .input-mask-field .input-container {
    margin: 0;
  }
}

.form__form-group--address {
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  // margin-top: 10px;
}

.form__form-group-field-signIn {
  width: 100%;
  display: flex;
  margin: auto;
  background: $color-secondary;
  border-radius: 10px;
  transition: all 0.3s linear;
  &:focus-within {
    box-shadow: 0 0 0 3px rgba(158, 39, 67, 0.1058823529) !important;
  }
  .input-mask-field {
    .input-container {
      &:focus-within {
        box-shadow: none !important;
      }
    }
  }
}

.form__form-group-field-code {
  width: 100%;
  display: flex;
  margin: auto;
  background: $color-secondary;
  border-radius: 10px;

  input {
    padding-left: 10px;
  }
}

.form__form-group-label {
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  @include themify($themes) {
    color: themed("colorLabelText");
  }
  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  // padding: 6px;
  // height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  margin: auto 3px;
  @include themify($themes) {
    background: $color-secondary;
    border: 1px solid $color-secondary;
  }
  svg {
    // fill: $color-primary;
    transition: all 0.3s;
  }
  &.active {
    // background: $color-primary-lighter;
    // border: 1px solid $color-primary-lighter;
    svg path {
      fill: $color-primary;
      stroke: $color-secondary;
    }
  }
}

.form__form-group-file {
  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    @include themify($themes) {
      border: 1px solid themed("colorFieldsBorder");
      color: themed("colorText");
    }
    &:hover {
      @include themify($themes) {
        background: themed("colorFieldsBorder");
      }
    }
  }
  span {
    padding-left: 10px;
  }
  input {
    display: none;
  }
}

.form__form-group-icon {
  // padding: 6px;
  // height: 32px;
  // border-radius: 50%;
  margin: auto 10px;
  @include themify($themes) {
    // background: $color-primary-lighter;
    // border: 1px solid themed("colorFieldsBorder");
  }
  svg {
    fill: $color-primary;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;
    &:after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;
  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }
  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    .form__form-group-date {
      margin-right: 0;
    }
    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;
  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  & > div {
    margin-bottom: 0;
  }
  .form__form-group-price {
    margin-right: 20px;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.form__add-taxonomy {
  .form__form-group {
    .label {
      margin-bottom: 6px;
    }
  }
  .form__form-group-label {
    color: #7f7f7f;
    margin-bottom: 6px;
  }
}
