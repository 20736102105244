.chats {
  padding-bottom: 40px;
  padding-right: 40px;

  .left-side-chats {
    margin-left: 10px;
    // max-width: 335px;
    // min-width: 335px;
    max-height: 40%;
  }
  .chat-cards .card-body {
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.chat-field-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 20px;
  padding: 0;
  background-color: #ffffff;

  .chat-initial-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
