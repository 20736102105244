.action-btn_all-user {
    background-color: #F8F8F8;
    height: 46px;
    width: 203px;
    font-size: 16px;
    text-align: center;
    padding-top: 8px;
    color: #7F7F7F;
    :hover {
        color: #7F7F7F;
      }
}
.action-btn_device {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    background-color: #F8F8F8;
    width: 46px;
}