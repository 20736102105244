.tab-permissions-container {
  // max-width: 480px;
  width: 100%;
  .ant-picker-input {
    margin: 0px 0px 0px 0px;
    height: 46px;
  }
  .ant-input-password {
    height: 46px;
  }
  .select-field .ant-select-selection {
    height: 46px;
  }

  .permissions-form-item_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .permission-field_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(158, 39, 67, 0.1);
    div {
      width: 50%;
    }
  }

  .select-target_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1px;
    margin-bottom: 8px;
    width: 100%;

    .select_container {
      width: 48%;
      margin-right: 10px;
    }

    .select_container.large {
      width: 55%;
    }
  }

  .ant-btn-link {
    padding: 5px 0;
  }

  .tree-select-field .ant-select-selection {
    padding-left: 0;
  }

  .permissions-form {
    flex-direction: column;
    padding: 20px 0 20px 0;
  }

  .permissions_container {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 2px;
  }
}

.permission-field {
  font-weight: 500;
  font-size: 16px;
}

