.table-task td {
  // white-space: nowrap;
}

.table-task {
  tr {
    td:nth-child(2) {
      padding: 0;
    }
    th:nth-child(2) {
      padding: 0;
    }
  }
}

.row-cursor {
  cursor: pointer;
}

.table-task tbody tr td:first-child {
  text-align: center;
}

.table-task tbody tr td .lnr {
  color: rgba(0, 0, 0, 0.65);
}

.table-task tbody tr td .fas {
  // color: rgba(0, 0, 0, 0.65);
}

.table-task tbody tr td .fa-star {
  // color: black;
  color: #f6da6e;
  cursor: pointer;
}

.table-row-red {
  color: #ba0000;
}

.table-row-bold {
  font-weight: bold;
}

.table-row-gray {
  color: #c6c3c6;
}

.popup {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  z-index: 999;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.fa-paperclip {
}

.DataLogs {
  .filter {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 40px;

    &.small {
      margin-bottom: 20px;
    }

    .logs__date_item {
      margin-right: 20px;
      width: 248px;

      .ant-picker-suffix {
        content: url(/assets/img/icon-calendar.svg);
        left: 85%;
        top: 11px;
        height: 24px;
        width: 24px;
        filter: invert(49%) sepia(1%) saturate(0%) hue-rotate(176deg)
          brightness(101%) contrast(81%);
        position: absolute;
      }

      .ant-calendar-picker-clear {
        background-color: #f8f8f8;
      }

      .ant-picker .ant-picker-input {
        border-radius: 10px;
        height: 46px;
        padding-left: 15px !important;
      }
    }
    .form__form-group + .form__form-group {
      margin-left: 20px;
    }
    .form__form-group {
      max-width: 200px;
    }
    button {
      min-width: 200px;
    }
  }

  .selected-logs-menu {
    display: flex;
  }
}
