.filter-contact {
  margin-left: 15px;
  width: 175px;
  // padding: 15px;
  .filter-icon {
    display: none;
  }
  .filter {
    // display: none;
    .form__form-group {
      margin-bottom: 10px;
    }
  }

  .profile__data_card {
    margin-bottom: 10px;
  }

  .profile__data-tooltip {
    margin-bottom: 3px;
  }

  .form__form-group .label {
    margin-bottom: 3px;
  }

  .ant-picker {
    padding-left: 0;
  }
}

.sidebar--collapse {
  .filter-contact {
    .filter {
      // display: none;
    }
    .filter-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.filter-contact_date-picker .ant-picker-header .ant-picker-year-btn {
  display: none;
}
