.contact {
    //  margin: 10px 10px;
    margin-left: 10px;
    .row-profile-user {
        margin-top: 20px;
    }
    .profile__avatar {
        margin: 0px auto 20px;
    }
}

.btn_container {
    padding: 0;
    margin-bottom: 20px;
}

.contact__card {
    margin: 0px 0px 0px 0px;
    height: 100%;
    min-width: 335px;
    border-radius: 20px;
    padding: 0;
    @include themify($themes) {
        background-color: themed("colorBackground");
    }
    .nav-item {
        font-weight: 500;
        // padding: 10px 10px;
        font-size: 16px;
        line-height: 16px;
    }
    .tab-content {
        padding: 0 30px 40px 30px;
        min-height: 490px;
    }
    .timeline__content {
        @include themify($themes) {
            background: themed("colorBackgroundBody");
        }
        &:after {
            @include themify($themes) {
                border-left-color: themed("colorBackgroundBody");
            }
        }
    }
    .timeline .timeline__item:nth-child(even) {
        .timeline__content {
            &:after {
                @include themify($themes) {
                    border-right-color: themed("colorBackgroundBody");
                }
            }
        }
    }
    &--calendar {
        .rbc-toolbar {
            -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
        }
    }
}

// .profile__tabs {
//   padding-bottom: 20px;
// }
.contact__information {
    padding: 20px 5px 5px 20px !important;
    display: flex;
    flex-direction: column;
    @include themify($themes) {
        border-bottom: 1px solid themed("colorBorder");
    }
    @media screen and (max-width: 320px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .contact__data {
        @media screen and (max-width: 320px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 0;
        }
    }
}

.contact__avatar {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 50%;
    // display: flex;
    justify-content: center;
    margin: 5px auto 20px;
    border-width: 1px solid;
    img {
        height: 150px;
        width: auto;
    }
    .avatar-img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    }
}

.contact__stats {
    display: flex;
    justify-content: space-around;
}

.contact__stat {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 20px;
    border-top: 0.5px solid #e5e5e5;
    border-right: 0.5px solid #e5e5e5;
    width: 100%;
    &:last-child {
        border-right: none;
    }
}

// .profile__stat :last-child {
//   border-right: none;
// }
.contact__stat-number {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    // margin: 0;
}

.contact__stat-title {
    // margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $color-additional-text !important;
}

.contact__data {
    margin-bottom: 5px;
    // width: calc(100% - 140px);
}

.contact__name {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    // text-transform: uppercase;
}

.contact__work {
    font-weight: 400;
    //   margin-bottom: 30px;
    margin-top: 5px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
}

.contact__data_card {
    width: 100%;
    // color: #7f7f7f
}

.contact__data-tooltip {
    font-size: 12px;
    line-height: 1.7;
    color: $color-additional-text !important;
}

.profile__contact {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
    background-color: $color-secondary;
    border-radius: 10px;
    padding: 15px 20px;
}

.contact__action-card {
    display: flex;
    flex-direction: column;
}

.contact__action-header {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 7px;
    color: $color-additional-text;
    text-align: center;
}

.contact__action-btns {
    display: flex;
    justify-content: space-between;
}

.contact__btn {
    // padding: 16px 22px;
    margin-left: 5px;
    margin-top: 10px;
    font-size: 16px;
    height: 46px;
    width: 140px;
}

.layout--collapse+.container__wrap {
    .btn_container {
        .contact__btn {
            margin-top: 10px;
            font-size: 16px;
            height: 46px;
            width: 142px;
        }
        .contact-left {
            margin-left: 15px;
        }
        .contact-rigth {
            margin-right: 15px !important;
        }
    }
}

.contact-left {
    margin-left: 10px;
}

.contact-rigth {
    margin-right: 10px !important;
}

.save-profile__btn {
    font-size: 16px;
    width: 100%;
}

.profile__activity {
    display: flex;
    border-bottom: solid 1px #e7e7e7;
    padding: 20px 0;
    &:last-child {
        border: none;
    }
    img {
        display: inline-block;
        margin-right: 20px;
        vertical-align: top;
        margin-top: 10px;
        width: auto;
        &:last-child {
            margin-right: 0;
        }
        @media screen and (max-width: 576px) {
            width: 100%;
        }
    }
}

.profile__activity-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    min-width: 64px;
    margin-right: 20px;
    img {
        height: 64px;
        width: 64px;
        margin: 0;
    }
}

.profile__activity-name {
    font-weight: 700;
    color: $color-blue;
}

.profile__activity-date {
    font-weight: 400;
    color: $color-additional;
    font-size: 10px;
}

.profile__current-tasks-title {
    padding: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    @include themify($themes) {
        border-bottom: 1px solid themed("colorBorder");
    }
    span {
        color: $color-additional;
        font-size: 13px;
    }
}

.profile__current-tasks {
    position: relative;
    padding-bottom: 40px;
}

.profile__current-task {
    display: flex;
    margin: 0 20px;
    padding: 10px 0 10px 28px;
    position: relative;
    @include themify($themes) {
        border-bottom: 1px solid themed("colorBorder");
    }
    &:last-of-type {
        border-bottom: none;
    }
    .badge {
        text-transform: uppercase;
        font-size: 9px;
        padding: 6px 10px;
        display: inline-table;
        height: 20px;
        color: #ffffff;
        margin-left: 5px;
        &.badge-info {
            background-color: $color-accent;
        }
        &.badge-error {
            background-color: $color-red;
        }
    }
}

.profile__current-task-checkbox {
    position: absolute;
    left: 0;
}

.profile__see-all-tasks {
    position: absolute;
    right: 20px;
    bottom: 20px;
}