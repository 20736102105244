.profile__avatar {
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
  // display: flex;
  justify-content: center;
  margin: 5px auto 20px;
  border-width: 1px solid;

  img {
    height: 150px;
    width: 150px;
    object-fit: fill;
  }

  .avatar-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
  }

  .cameraIcon {
    height: 2rem;
    width: 2rem;
    position: absolute;
    bottom: 20px;
    right: 65px;
    background-color: white;
    &:hover {
      background-color: white;
    }
  }
}

.layout--collapse + .container__wrap {
  .profile__avatar {
    .cameraIcon {
      height: 2rem;
      width: 2rem;
      position: absolute;
      bottom: 20px;
      right: 80px;
      background-color: white;
      &:hover {
        background-color: white;
      }

      transition: left 0.1s linear;
    }
  }
}

.container {
  margin-bottom: 20px;

  .size-description,
  .type-description {
    margin: 0;
    font-size: 10px;
    text-align: center;
  }
}

.container-cropper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 90%;
  padding: 10px;
}

.container-image {
  display: flex;
  width: 150px;
  justify-content: center;
  align-items: center;
}

.cropper {
  height: 90%;
  position: relative;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 60%;
}

.container-buttons {
  border: 1px solid #f5f5f5;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
