.select-field {
  .ant-select-selection {
    border-radius: 0;
    border: 1px solid #f2f4f7;
    .ant-select-selection__placeholder {
      font-size: 12px;
      padding: 1px 0 0 13px;
    }
  }
  .ant-select-search__field {
    border: none !important;
    padding: 0 !important;
  }
}

.select-field .ant-select-selection {
  height: 46px;
  border: 0px solid #f8f8f8 !important;
  border-radius: 10px !important;
  background-color: #f8f8f8;
}
