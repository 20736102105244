

.container { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    
}

.input {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   cursor: pointer;
}


.container svg {
    border: 2px rgb(178, 179, 183) solid;
    height: 16px;
    width: 16px;
    margin-right: 6px;
    border-radius: 2px;
}

.container p {
    margin: 0;
    padding-top: 2px;
}

.svgActive {
    border-color: rgb(158, 39, 67)!important;
    
}