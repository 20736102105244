$border: 1px solid #eaeaea;
$text-color: #3b3b3b;
$down-icon-color: #282828;

.header {
  margin-bottom: 50px;
}

.tree-factory {
  width: 100%;
  padding: 20px;

  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background: none;
  }

  .tree-factory .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .tree-factory .ant-tree .ant-tree-node-content-wrapper,
  .tree-factory .tree-title {
    width: 100%;
  }

  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree .ant-tree-node-content-wrapper,
  .tree-title {
    background: none;
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .tree-factory .ant-tree {
    display: flex;
    flex-direction: column;
  }

  .ant-tree li ul {
    display: block;
    width: 100%;
  }

  .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
    display: none;
  }

  .ant-tree span.ant-tree-switcher {
    display: none;
  }

  .ant-tree li {
    padding: 0;
  }

  .tree-node_children {
    margin-left: 31px;
    margin-right: 49px;
    height: 55px;
    background: none;

    .icon-trash {
      height: 21px;
      width: 17px;
      margin-left: 20px !important;
    }

    .ant-tree-treenode-leaf-last {
      margin-bottom: 10px;
    }
  }

  .tree-node {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 55px;
  }

  .tree-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    color: $text-color;

    div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .tree-title-icon_children {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: 15px;
      margin-right: 25px;

      i {
        font-size: 16px;
      }

      .factory-icon {
        content: url(../../../../assets/img/factory-icon.svg);
        height: 22px;
        width: 22px;
      }
    }

    .tree-title-icon {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border-right: $border;
    }

    .title-txt_children {
      padding: 0;
      font-size: 16px;
      font-weight: 500;
    }

    .title-txt {
      // top: 15px;
      position: relative;
      align-self: center;
      padding-left: 20px;
      line-height: 16px;
      font-size: 16px;
      font-weight: 500;
    }

    .search-title {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      background-color: #9e2743;
    }

    .fa-folder {
      top: 15px;
      position: relative;
      align-self: flex-start;
      margin-right: 5px;
      color: rgba(59, 59, 59, 1);
      font-size: 22px;
    }

    .fa-building {
      top: 15px;
      position: relative;
      align-self: flex-start;
      margin-right: 5px;
      font-size: 22px;
    }

    .factory-folder {
      content: url(../../../../assets/img/folder-icon.svg);
      height: 22px;
      width: 22px;
    }

    .factory-icon {
      content: url(../../../../assets/img/factory-icon.svg);
      height: 22px;
      width: 22px;
    }

    .factory-icon-small {
      content: url(../../../../assets/img/factory-icon.svg);
      height: 22px;
      width: 22px;
    }
    .left-container {
      height: 32px;
      width: 20px;
      justify-content: center;
    }

    .fa-chevron-down {
      color: $down-icon-color;
      position: relative;
      align-self: center;
      font-size: 12px;
    }

    .lnr-trash {
      width: 50px;
      min-height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border-left: $border;
      margin-left: 5px;
      font-size: 18px;
    }

    .icon-trash {
      height: 21px;
      width: 17px;
      margin-left: 40px;
    }

    .text {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
  }
}
