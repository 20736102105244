.confirm-modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(158, 39, 67, 1);
    padding: 0px 20px;
    .message {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
        text-align: center;
    }
    .button-bar {
        margin-bottom: 30px;
    }
    button {
        width: 150px;
        height: 45px;
        margin: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
    }
    .confirm-btn {
        border: none;
        background: rgba(255, 255, 255, 0.5);
        color: #fff;
    }
    .cancel-btn {
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: none;
        color: rgba(255, 255, 255, 0.5);
    }
}