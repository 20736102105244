.FormUser {
  width: 100%;
  .form__form-group-error {
    color: #cc0000;
    font-size: 12px;
    font-style: italic;
  }
  .form__form-group .label {
    display: block;
    margin-bottom: 10px;
  }
  .input-password-field {
    input {
      margin-top: 0;
    }
  }
  .ant-input {
    color: #3b3b3b;
  }
}

.email-date-block {
  width: "100%";
  margin: 0;
  margin-bottom: 10px;
  .ant-picker-suffix {
    content: url(/assets/img/icon-calendar-custom.svg);
    left: 0;
    top: 11px;
    height: 24px;
    width: 24px;
    position: absolute;
  }
  .ant-picker {
    background-color: #f8f8f8;
    margin-top: 10px !important;
    max-height: 46px;
  }
  .ant-picker-input {
    position: relative;
    padding-left: 30px !important;
  }
  .profile__data_card {
    margin-bottom: 10px;
  }
}

.password-field {
  .input-password-field {
    margin-bottom: 10px;
  }
}

.input-password-field {
  input {
    margin-top: 0;
    font-size: 13px;
  }
}

.tab-contact-container {
  max-width: 480px;
  .ant-picker-input {
    margin: 0px 0px 0px 0px;
    height: 46px;
    input {
      margin-top: 0;
      color: #3b3b3b;
    }
  }
  .ant-input-password {
    height: 46px;
  }
  .select-field .ant-select-selection {
    height: 46px;
  }
  .add-group-field_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgba(158, 39, 67, 0.1);
    div {
      width: 50%;
    }
  }

  .input-mask-field {
    .input-container {
      padding: 0px 0px;
    }
  }
}

.contact-row-field {
  display: flex;
  line-height: 12px;
  .avatar-block {
    position: relative;
    .input-label {
      color: rgb(127, 127, 127) !important;
    }
    .avatar {
      color: red;
      position: absolute;
      top: 20px;
      z-index: 99;
      cursor: pointer;
    }
  }
  .contact-left-field {
    padding: 0;
    padding-right: 10px;
  }
  .contact-right-field {
    padding: 0;
    padding-left: 10px;
  }
  .contact-select-field {
    margin-bottom: 10px;
  }
  .input-mask-field .input-label {
    font-size: 12px;
    line-height: 12px;
    // margin-bottom: 0;
  }
  .input-mask-field .input-container {
    width: 100%;
    margin: 0px;
  }
  .profile__data-tooltip {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0;
  }
}

.contact-row-name {
  column-gap: 10px;
}

.input-password-field .profile__data-tooltip {
  margin-bottom: 5px;
}

.generate-pw-field {
  cursor: pointer;
  color: #9e2743;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
  width: fit-content;
}

.group-permission-field {
  font-weight: 500;
  font-size: 16px;
}

.contact__btn {
  // margin: 10px;
  font-size: 16px;
  // width: 152px;
  height: 46px;
}

.permission-row-field {
  margin-bottom: 20px;
}

.permissions__btn {
  width: 96px;
}

.factory-field {
  margin: 10px 0px 10px 0px;
}

.user__avatar {
  height: 120px;
  width: 120px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border-width: 1px solid;
  cursor: pointer;

  img {
    height: 120px;
    width: 120px;
    object-fit: fill;
  }
}
