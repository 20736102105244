$color-primary: #9e2743;

.form__form-group-filter {
  display: flex;
  align-items: center;
  height: 15px;
  margin-bottom: 15px;
}

.checkbox-btn__checkbox-custom {
  width: 15px;
  height: 15px;
  border: 1px solid $color-primary;
  // &:hover {
  //   background-color: $color-primary !important;
  //   border-color: $color-primary !important;
  // }
  // &:checked {
  //   background-color: $color-primary;
  // }
}
