.task-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
}

.search-input {
  width: 235px;
  margin-right: 10px;
}

.create-btn {
  width: 113px;
  margin-left: 10px;
}

// Create task modal styles ===

.modal-tabs .tabs__wrap {
  margin-bottom: 45px;
}

.tabs__wrap {
  margin-bottom: 46px;
}

.nav-tabs {
  border-color: transparent;
}

.modal-tab {
  color: rgba(122, 122, 122, 1);
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 10px 0 !important;

  &:hover {
    color: rgba(122, 122, 122, 0.6);
    border: none;
  }
}

.modal-tab_active {
  color: rgba(59, 59, 59, 1);
  border-bottom: 1px solid #9e2743 !important;
}

.submit-btn {
  width: 176px;
}

.group-plus-icon {
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 12px;
  bottom: -10px;
}

.tab-task-container {
  max-width: 480px;

  .ant-picker-input {
    margin: 0px 0px 0px 0px;
    height: 46px;
  }

  .ant-input-password {
    height: 46px;
  }

  .select-field .ant-select-selection {
    height: 46px;
  }

  .add-group-field_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgba(158, 39, 67, 0.1);

    div {
      width: 50%;
    }
  }

  .ant-picker {
    padding-left: 0;
    padding-top: 4px;
    height: 46px;
    position: relative;

    .ant-picker-input {
      &:first-child {
        padding-left: 0 !important;
      }
    }

    .ant-picker-suffix {
      right: 0;
      left: unset;
      content: url(/assets/img/icon-calendar-custom-2.svg);
    }
  }
}

.task-row-field {
  display: flex;
  width: 100%;
  flex-direction: row;
  line-height: 12px;
  margin: 0;
  margin-bottom: 10;
  flex-wrap: wrap;

  .input-mask-field .input-label {
    font-size: 12px;
    line-height: 12px;
    // margin-bottom: 0;
  }

  .input-mask-field .input-container {
    margin: 0px;
  }

  .profile__data-tooltip {
    font-size: 12px;
    line-height: 12px;
  }

  .date-error {
    padding-left: 15px;
  }
}

// Upload document ===
.upload-document {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.empty {
    min-height: 20vh;
  }

  .upload-container {
    width: 100%;
    height: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px #7f7f7f solid;
    margin-bottom: 20px;

    &:active {
      opacity: 0.6;
    }

    img {
      width: 23px;
      margin-right: 15px;
    }

    span {
      user-select: none;
      font-size: 16px;
      font-weight: 500;
      color: #7f7f7f;
    }
  }

  .help-txt {
    display: flex;
    width: 360px;
    font-size: 12px;
    text-align: center;
  }
}

.plus-icon {
  cursor: pointer;
  width: 5%;
}

.taxonomy-select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 68px;
  .form__form-group {
    width: 85%;
  }
}

.files-list {
  width: 100%;
  margin-top: 20px;
}

.file-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  margin-top: 3px;
  padding-bottom: 3px;

  p {
    margin-top: 0;
  }

  .file-item_text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    padding-top: 8px;
    width: 75%;
    flex-shrink: 0;

    .file-item_title {
      width: 95%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .file-item_icons-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 10%;
    margin-right: 10px;
  }

  .file-item_icon-container {
    width: 15%;
    flex-shrink: 0;
    margin: 5px 0
  }

  .file-item_icon {
    width: 85%;
  }

  .file-item_small-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}

.selected-tasks-menu {
  margin-bottom: 0 !important;
}

.details-tab_dates,
.details-tab_users {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.details-tab_dates-column,
.details-tab_users-column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.bold-text {
  font-size: larger;
}

section[class^="details-tab"] {
  margin-bottom: 10px;
}

.finish-task-modal_content,
.reassign-task-modal_content,
.print-task-modal_content {
  padding: 20px 0;

  .finish-task-modal_date-field {
    .ant-picker {
      padding-left: 0;
      padding-top: 4px;
      height: 46px;
      position: relative;

      .ant-picker-suffix {
        width: 20px;
        height: 18px;
        right: 5px;
        left: unset;
        top: 2px;
        position: absolute;
      }
    }
  }

  .submit-btn {
    margin-top: 30px;
  }

  .checkbox-btn__label {
    font-size: 14px;
    &:hover {
      color: inherit;
    }
  }

  .checkbox-btn:hover .checkbox-btn__label {
    color: #000000;
  }

  .ant-select {
    width: 100%;
    margin-bottom: 30px;
  }
}

.print-tasks-list {
  width: 100%;
  box-sizing: border-box;
  padding: 15mm;
}

.task-coments-field-area {
  .forward-btn-container {
    width: 0px;
    height: 0px;
    display: none;

    .chat-forward-button {
      background-color: #ffffff;
      border: none;
      height: 0px;
      width: 0px;

      img {
        display: none;
        width: 20px;
      }
    }
  }

  .chat-down-container {
    right: 40px;
    bottom: 110px;
  }

  .chat-bar-right {
    padding-right: 0px !important;
  }
}

.unread-indicator {
  height: 8px;
  width: 8px;
  top: 9px;
  right: 3px;
  background-color: #9E2743;
  border-radius: 50%;
  display: inline-block;
  position: absolute;

  &.comment {
    right: 0;
  }
}

.details-tab_name, .details-tab_description {
  div {
    overflow-wrap: break-word;
  }
}