.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed("colorBackgroundBody");
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }

  @media screen and (min-width: 1366px) {
    padding-left: 250px;

    .chat-info-container {
      .chat-label {
        width: 140px;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    padding-left: 250px;

    .chat-info-container {
      .chat-label {
        width: 160px;
      }
    }
  }

  @media screen and (min-width: 1900px) {
    padding-left: 250px;

    .chat-info-container {
      .chat-label {
        width: 300px;
      }
    }
  }
}

.layout {
  &.layout--collapse {
    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }

      .chats {
        padding-left: 50px;

        .chat-info-container {
          .chat-label {
            width: 140px;
          }
        }
      }
      @media screen and (min-width: 1200px) {
        .chats {
          padding-left: 50px;

          .chat-info-container {
            .chat-label {
              width: 150px;
            }
          }
        }
      }

      @media screen and (min-width: 1366px) {
        .chats {
          padding-left: 50px;

          .chat-info-container {
            .chat-label {
              width: 170px;
            }
          }
        }
      }

      @media screen and (min-width: 1440px) {
        .chats {
          padding-left: 50px;

          .chat-info-container {
            .chat-label {
              width: 200px;
            }
          }
        }
      }

      @media screen and (min-width: 1900px) {
        .chats {
          padding-left: 50px;

          .chat-info-container {
            .chat-label {
              width: 350px;
            }
          }
        }
      }
    }
  }

  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}
