.chat {
  height: calc(100vh - 110px);
  min-height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  @media screen and (max-width: 1023px) {

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: $color-additional;
    }

    &.chat--open {

      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    }
  }
}

.chat__contact-list {
  width: 335px;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;
  border-right: 1px solid #f2f4f7;

  @include themify($themes) {
/*    border-right: 1px solid themed('colorFieldsBorder');*/
    background-color: themed('colorBackground');
  }

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    transform: translateX(calc(-100% - 1px));
    position: absolute;
    z-index: 1;

    &.chat__contact-list--open {
      transform: translateX(0);
      border-right: none;
    }
  }
}

.chat__contacts {
  overflow: auto;
  height: calc(100% - 55px);

  button {
    width: 100%;
    padding: 0;
    border: none;
    text-align: left;
    background: transparent;
  }
}

.chat__contact {
  height: 72px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorFieldsBorder');
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  &.chat__contact--active {

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    &:hover {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }
}

.chat__contact-avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto 10px auto 15px;
  min-width: 50px;

  img {
    height: 50px;
    position: relative;
  }
}

.chat__user-avatar {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: auto 10px auto 0px;
  min-width: 50px;
}

.chat__user-avatar.online::after{
  content: '';
  position: absolute;
  right: 6px;
  bottom: 0;
  width: 8px;
  height: 8px;
  background: #3ddb42;
  border-radius: 10px;
}

.chat__user-avatar-image-block {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.modal-content .modal__body{
  font-size: 12px;
}

.chat__dialog-messages-wrap{

  .chat__user-avatar {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0px 10px 0px 0px;
    min-width: 40px;
    align-self: end;

    .chat__user-avatar-image-block {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}



.chat__contact-preview {
  margin: auto 20px auto 0;
  width: calc(100% - 80px);
}

.chat__contact-name {
  font-weight: 500;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.chat__contact--active .chat__contact-name{
  font-weight: 700;
}

.chat__contact-post {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 1.2;
  color: $color-additional;
}

.chat__contact-last-message {
  margin: 4px 0 0 0;
  font-size: 12px;
  line-height: 1.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chat__dialog {
  width: calc(100% - 335px);
  overflow: hidden;
  position: relative;
  border-bottom: 15px solid white; /* hack for adaptive chat textare*/
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
}

.chat__contacts-scroll {
  height: 100%;
  padding-left: -15px;
  .scroll-content {

    &, & > div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.chat__scroll {
  height: calc(100% - 115px);

  .scroll-content {

    &, & > div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.chat__dialog-messages-wrap {
  min-height: 100%;
  width: 100%;
  display: flex;
}

.chat__dialog-messages {
  padding: 20px;
  margin-top: auto;
  width: 100%;
}

.chat__download-info{
  position: absolute;
  bottom: -10px;
  font-size: 10px;
  color: silver;
  padding-left: 10px;
}


.chat__bubble-message-wrap {
  white-space: pre-wrap;
  max-width: 590px;
  padding: 10px 40px 10px 20px;
  border-radius: 0 20px 20px 20px;
  position: relative;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.10) !important;
  @include themify($themes) {
    background-color: themed('colorBubble');
  }

  @media screen and (min-width: 1366px) {
    position: static;
  }

  &.chat__bubble-message-wrap--file {
    padding: 10px 20px 20px 20px;
  }

  img {
    max-width: 215px;
  }
}

.own .chat__bubble-message-wrap {
  background-color: #deefff;
}

.chat__bubble-download-wrap {
  max-width: 215px;
  margin-top: 10px;
  font-size: 10px;

  @media screen and (min-width: 1366px) {
    position: absolute;
    top: 0;
    left: 315px;
    max-width: calc(100% - 375px);
    width: 300px;
    margin-top: 0;
  }
}

.chat__bubble-file-name {
  white-space: nowrap;
  display: flex;

  span:first-child {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span:last-child {
    color: $color-additional;
    margin-left: 5px;
  }
}

.chat__bubble-download {
  color: $color-accent;
  font-weight: 500;

  &:hover {
    color: $color-accent-hover;
  }
}

.chat__bubble-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  display: none;

  img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 425px) {
    display: block;
  }
}

.chat__bubble-contact-name {
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  color: $color-accent;
  padding-right: 50px;
}

.chat__bubble-message {
  margin: 0;
  font-size: 12px;

  @include themify($themes) {
    color: themed('colorText');
  }

}

.chat__bubble-date {
  position: absolute;
  right: 36px !important;
  color: $color-additional !important;
  font-size: 11px;
  margin: 0;
  top: 10px;

  @media screen and (min-width: 1366px) {
    right: 0;
  }
}

.chat__bubble {
  margin-bottom: 20px;
  position: relative;

  &.chat__bubble--active {

    .chat__bubble-message-wrap {

      @include themify($themes) {
        background-color: themed('colorBubbleActive');
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.chat__dialog-select-message, .chat__dialog-messages-empty {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: $color-additional;

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }
}

.chat__search {
  display: flex;
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    pointer-events: none;
  }
}

.chat__search-input {
  width: 100%;
  height: 100%;
  padding-left: 38px;
  padding-right: 10px;
  font-size: 11px;
  transition: all 0.3s;
  background: transparent;
  border: none;

  &:focus, &:active {
    outline: none;
    border-color: $color-blue;
  }

  &::-webkit-input-placeholder {
    color: $color-additional;
  }

  &::-moz-placeholder {
    color: $color-additional;
  }

  &:-moz-placeholder {
    color: $color-additional;
  }

  &:-ms-input-placeholder {
    color: $color-additional;
  }
}

.topWrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  min-height: 50px;
}
.chat_settings{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px
}
.chat__text-field {
  width: 100%;
  position: absolute;
  bottom: 0;

  @include themify($themes) {
    border-top: 1px solid themed('colorFieldsBorder');
  }
}

.chat__form {
  width: 100%;
  position: relative;
  padding: 12px 20px;
  padding-bottom: 0;
  background: white;
}

.chat__field-input {
  width: 100%;
  margin-right: 120px;
  height: 36px;
  border-radius: 8px;
  resize: none;
  overflow: hidden;
  border: none;
  font-size: 11px;
  transition: 0.3s;
  padding-left: 15px;
  padding-right: 65px;
  line-height: 120%;

  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
    border: 1px solid themed('colorBackgroundBody');
  }

  &:focus, &:hover, &:active {
    border-color: $color-accent;
    outline: none;
    box-shadow: none;
  }
}

.chat__text-button {
  width: 100px;
  height: 38px;
  padding-top: 8px;
  margin-bottom: auto;
}

.chat__field-controls {
  position: absolute;
  right: 35px;
  bottom: 7px;
  height: 36px;
  display: flex;
}

.chat__icon-button {
  background: transparent;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;
  color: $color-accent;

  .fal{
    font-size: 17px;
  }

  svg {
    height: 22px;
    width: 22px;
/*    fill: $color-additional;*/
    fill: $color-accent;
    transition: 0.3s;
  }

  &:hover {

    svg {
      fill: $color-accent;
    }
  }
}

.chat__topbar {
  display: flex;
  // height: 143px;
  align-items: center;
  padding-top: 10px;

  padding-bottom:10px;
  padding-left: 20px;
  height: 61px;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorFieldsBorder');
    background-color: themed('colorBackground');
  }
}

.chat__topbar-button {
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  width: 50px;
  height: 50px;

  &.chat__topbar-button--menu {
    margin-right: -20px;
  }

  &:hover {
    .chat__topbar-button-icon {
      fill: $color-accent;
      color: $color-accent;
    }
  }

  @media screen and (min-width: 1024px) {
    &.chat__topbar-button--menu {
      display: none;
    }
  }
}

.chat__topbar-button-icon {
  height: 18px;
  width: 18px;
  transition: 0.3s;

  @include themify($themes) {
    fill: themed('colorIcon');
  }
}

.chat__topbar-contact {
  margin-left: 20px;
  cursor: pointer;
}

.chat__topbar-contact-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 14px;
  max-width: 500px;
  font-weight: bold;
}


.chat__topbar-contact-post {
  margin: 0;
  color: $color-additional;
  font-size: 11px;
  line-height: 1.45;
}

.chat__topbar-right {
  margin-left: auto;
  display: flex;
}

.chat__topbar-controls {
  display: none;

  @media screen and (min-width: 698px) {
    display: flex;
  }
}

.main_info_chat {
  display: flex;
  width: 100%;
}

.chat_additional {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div {
    display: flex;
  }

  .time {
    padding-bottom: 10px;
    width: 47px;
    font-size: 11px;
  }

}

.chat_details {
  width: 200px;
  padding-right: 20px;
}

.msg_options {
  display: flex;
  align-items: center;
  align-self: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.pinnedMsg {
  height: 90%;
  width: 80%;
  p {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.unread {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #3498DB;

  p {
    font-size: 10px;
    color: white;
  }

  margin-right: 3px;
}

.personal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: orange;

  p {
    font-size: 10px;
    color: white;
  }
}


.pinnedCont {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
/*  padding-bottom: 5px;*/
  position: absolute;
  max-height: 60px;
  width: 100%;
  z-index: 2;
  background: white;
  border-bottom: 1px solid #f2f4f7;
  transition: all 0.3s ease-out;
  cursor: pointer;

  p {
    color: #3498DB;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &.active{
    align-items: flex-start;
    white-space: pre-wrap;
    max-height: 80%;
    p{
      -webkit-line-clamp: none;
    }
  }

  @include themify($themes) {
/*    background-color: themed('colorBubble');*/
  }
}

.pinnedMain {
  width: 100%;
}

.pinnedTime {
  font-size: 11px;
  line-height: 110%;
  color: silver;
  position: absolute;
  right: 70px;
  top: 7px;
}


.pinnedName{
  margin-bottom: 3px;
}

.chat__name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.pinnedH {
  color: #3498DB !important;
  font-weight: bold;
}


.replyCont {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  @include themify($themes) {
    background-color: themed('colorBubble');
  }
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 15px;
}

.replyName {
  color: #3498DB !important;
  padding-left: 20px;
}

.replyBody {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.replyMsg {
  height: 40px;
  box-shadow: 0 -10px 5px -10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.sendBtn {
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.replyTo {
  display: flex;
  align-items: center;

  p {
    font-size: 11px;
  }
}

.add_chat {
  display: flex;
  justify-content: left;
  text-align: left;
  cursor: pointer;
  padding-left: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #f2f4f7;

  i{
    font-size: 30px !important;
    color: #3498db;
  }

  p {
    font-size: 16px;
  }
}

.chat_settings {
  margin-left: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.chat_menu_notiff {
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
  }
}

.modal__header .chat_menu_notiff{
  float: right;
  margin-right: 40px;

  p{
    font-size: 14px;
  }

  .MuiSwitch-thumb{
    color: #005f9f;
  }

  .MuiSwitch-track{
    background-color: #a6d9fc;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #a6d9fc;
  }
}

