.row-profile-user {
  width: "100%";
  margin: 0;
  margin-top: 30px;

  .ant-picker-input {
    position: relative;
  }

  .inner-phone-number {
    input {
      padding-left: 15px;
    }
  }

  .ant-picker-suffix {
    content: url(/assets/img/icon-calendar-custom.svg);
    left: 0;
    top: 11px;
    height: 24px;
    width: 24px;
    position: absolute;
  }

  .profile__data_card {
    .profile__data-tooltip {
      margin-bottom: 3px;
    }
  }

  .password-container {
    .profile__data-tooltip {
      margin-bottom: 3px;
    }
  }

  .input-password-field {
    .profile__data-tooltip {
      margin-bottom: 3px;
    }
  }

  .email-container {
    &:hover {
      cursor: pointer;
    }
  }
}

.password-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
