$secondaryTxtColor: rgba(127, 127, 127, 1);

// COLUMNS =====

.column-name {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: normal;

  img {
    width: 40px;
    height: 40px;
    margin: 0 20px 0 20px;
    object-fit: fill;
    border-radius: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;  

    .full-name {
      font-size: 16px;
      font-weight: 500;
      color: #393939;
    }

    .position {
      font-size: 14px;
      font-weight: 500;
      color: $secondaryTxtColor;
      text-align: start;
    }
  }
}

.column-with-secondary-txt {
  display: block;
  // text-align: center;
  text-align: start;
  font-size: 14px;
  color: $secondaryTxtColor;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;  
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;  
  text-align: start;
}
