$border: 1px solid #eaeaea;
$text-color: #3b3b3b;
$down-icon-color: #282828;

.TreeTaxonomy {
  padding: 20px;
  position: relative;

  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background: none;
  }

  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background: none;
  }

  .ant-tree .ant-tree-node-content-wrapper:hover {
    background: none;
  }

  .tree-factory
    .ant-tree
    li
    .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .tree-factory .ant-tree li .ant-tree-node-content-wrapper,
  .tree-factory .tree-title {
    width: 100%;
    background-color: transparent;
  }

  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree li .ant-tree-node-content-wrapper,
  .tree-title {
    background: none;
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .tree-factory .ant-tree li {
    display: flex;
    flex-direction: column;
  }

  .ant-tree li ul {
    display: block;
    width: 100%;
  }

  .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
    // display: none;
  }

  .ant-tree li span.ant-tree-switcher {
    // display: none;
    top: 2px;
    margin-right: 10px;
    align-self: center;
  }

  .ant-tree li {
    padding: 0;
  }

  .ant-tree
    li
    span.ant-tree-switcher.ant-tree-switcher_open
    + span
    .ant-tree-title
    .tree-title {
    margin-bottom: 15px;
  }

  .tree-node_children {
    align-items: center;
    flex-direction: column;
    margin-left: 31px;
    margin-right: 49px;
    height: 55px;
    background: none;
  }

  .ant-tree-child-tree {
    .icon-trash {
      height: 21px;
      width: 17px;
      margin-left: 20px !important;
    }
  }

  .tree-node {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 55px;
    // border-bottom: $border;
    // border-top: $border;

    &:last-child {
      // border-bottom: $border;
    }
  }

  .tree-title {
    width: 100%;
    // height: 32px;
    margin-bottom: 32px;

    display: flex;
    color: $text-color;

    align-items: center;
    flex-wrap: nowrap;

    div {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .search-title {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      background-color: #9e2743;
    }

    .fa-folder {
      margin-right: 5px;
      color: #ffe68c;
      font-size: 22px;
    }

    img {
      width: 30px;
      min-height: 30px;
      // min-width: 25px;
      // height: 25px;
      margin-right: 20px;
    }

    .left-container {
      height: 32px;
      width: 20px;
      justify-content: center;
    }

    .fa-chevron-down {
      color: $down-icon-color;
      position: relative;
      align-self: center;
      font-size: 12px;
    }

    .lnr-trash {
      margin-left: 5px;
      font-size: 18px;
    }

    .icon-trash {
      height: 21px;
      width: 17px;
      margin-left: 40px;
    }

    .text {
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
  }

  .taxonomy-spinner {
    position: absolute;
    display: flex;
    // align-items: center;
    justify-content: center;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
}
}
