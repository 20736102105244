.send-notifi-form {
    .form__form-group .label {
        margin-bottom: 6px;
    }
    .text-field  textarea,
    .input-mask-field .input-container {
        margin: 0
    }

    .input-mask-field {
        margin-bottom: 10px;
    }
    .input-mask-field .input-container{
        padding: 0;
     }

    .text-field  textarea{
        height: 100px;
        resize: none;
    }

    .set-header-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
    }
    .chat-modal-search-row {
        width: 100%;
    }
    .search-input {
        flex: 1
    }
    .user-list-container {
        width: 100%;
        padding-right: 5px;
    }

    .ant-checkbox-input {
        height: auto;
        * {
            outline: none;
        }
    }

    .sent-to-email {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p{
            margin: 0
        }



        .MuiCheckbox-colorSecondary.Mui-checked {
            color: #9e2744;
        }
    }
}

