$bg-color-lighter: rgba(158, 39, 67, 0.1);
$bg-btn-greenlighter: rgba(24, 101, 36, 0.1);
$primary-color: #9e2743;
.chat-card-container {
  display: flex;
  background-color: #ffffff;
  padding: 15px 20px 15px 20px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(234, 234, 234, 0.5);
  .chat-card-mainContent {
    display: flex;
    flex-direction: row;
  }
  .chat-card-avatar {
    padding-right: 20px;
  }
  .chat-card-avatarText {
    font-size: 20px;
  }
  .chat-card-rightContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .chat-card-dateFromSend {
      color: #7a7a7a;
      font-size: 12px;
      line-height: 16px;
    }
    .chat-card-indicator {
      align-self: flex-end;
    }
  }
  .chat-card-pinIcon {
    margin-bottom: 5px;
  }
  &:hover {
    cursor: pointer;
    background-color: $bg-color-lighter;
  }
}

.chat-card-container.active {
  background-color: #f8f8f8;
}

.chats-search-row {
  display: flex;
  justify-content: space-between;
  .search-input {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    color: #b2b3b7;
  }
  .search-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .settings-chats-container {
    display: flex;
    flex-direction: row;

    .settings-chats-item {
      margin-left: 10px;
    }
  }

  .setting-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-btn.ant-btn-default {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    background-color: rgba(158, 39, 67, 0.1);
    border: none;
    &:hover,
    &:active {
      outline: none !important;
      border: none;
    }
    &:focus,
    &:active:focus {
      color: inherit !important;
      border: none;
    }
  }

  .create-chat-btn,
  .chats-settings-btn {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    background-color: rgba(158, 39, 67, 0.1);
    border: none;
    &:hover,
    &:active,
    &:focus {
      border: none;
      background-color: rgba(158, 39, 67, 0.1) !important;
    }
    &::before {
      display: none;
    }
  }
}

.chatloader-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.attachments-container {
  display: flex;
  flex-direction: row;
}

/* WEBCAM MODAL STYLES */

/* CHAT MODAL STYLES */

.chat-modal-container {
  .chat-modal-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
  }

  /* Scrollbar settings */

  /* Firefox scrollbar settings */
  scrollbar-color: #9e2743 rgba(158, 39, 67, 0.1);
  scrollbar-width: 7px;

  ::-webkit-scrollbar {
    width: 7px;
    display: flex;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(158, 39, 67, 0.1);
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9e2743;
    max-height: 40px;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #9e2743;
  }
}

.chat-settings-modal-container {
  .chat-settings-background {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    p {
      color: #3b3b3b;
      font-size: 16px;
      line-height: 12px;
    }
    .settings-background-button {
      button {
        width: 20px;
        height: 46px;
        border: none;
        border-radius: 10px;
        background-color: #ffffff;
      }
      p {
        color: #3b3b3b;
        font-size: 16px;
        line-height: 12px;
      }
    }
  }
  .last-chat-setting {
    margin-bottom: 20px;
  }
  .chat-settings-pin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    p {
      color: #3b3b3b;
      font-size: 16px;
      line-height: 12px;
    }
    .unpin-container {
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='rgba(158, 39, 67, 1)' /></svg>");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%, auto;
    }
  }
  .add-member-settings-default {
    margin-top: 24px;
    // margin-bottom: 24px;

    button {
      border: none;
      background-color: #ffffff;
      padding: 0;
    }
    p {
      color: $primary-color;
      font-size: 16px;
      line-height: 12px;
    }
  }
  .chat-settings-header {
    margin-bottom: 40px;
    .chat-settings-basic {
      display: flex;
      flex-direction: row;
      align-items: center;
      .input-field {
        margin-left: 22px;
        input {
          width: 100%;
          background-color: #f8f8f8;
          height: 46px;
          font-size: 16px;
          line-height: 16px;
          font-weight: 500;
          padding-left: 10px;
        }
        input:focus {
          outline: none;
        }
        ::placeholder {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    .chat-settings-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .search-users-row {
        width: 70%;
      }
      .input-mask-field {
        .input-container {
          margin: 0px;
          input {
            background-color: #f8f8f8;
            height: 46px;
            text-align: center;
          }
        }
      }
      .notification-toggle-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        .ant-switch {
          height: 28px;
          width: 50px;
        }
        .ant-switch-handle::before {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          left: 0;
        }
        .ant-switch-checked .ant-switch-handle {
          left: calc(100% - 24px - 2px);
        }
      }
      .input-label {
        font-size: 12px;
        color: #7f7f7f;
        margin-bottom: 10px;
      }
      .search-input {
        height: 40px;
        background-color: #f8f8f8;
        width: 100%;
        ::placeholder {
          color: #b2b3b7;
          font-size: 12px;
          line-height: 14px;
        }
      }
      .input-field {
        margin-bottom: 20px;
        input {
          width: 100%;
          background-color: #f8f8f8;
          height: 46px;
          font-size: 16px;
          line-height: 16px;
          font-weight: 500;
          padding-left: 10px;
        }
        input:focus {
          outline: none;
        }
        ::placeholder {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    [data-theme="compact"]
      .site-collapse-custom-collapse
      .site-collapse-custom-panel,
    .site-collapse-custom-collapse .site-collapse-custom-panel {
      margin-bottom: 20px;
      overflow: hidden;
      background: #ffffff;
      border: 0px;
      border-radius: 2px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding-bottom: 4px;
      .collapsed-row {
        padding: 5px 5px 5px 5px;
        border-radius: 10px;
        &:hover {
          background-color: $bg-color-lighter;
          cursor: pointer;
        }
      }
    }
    .ant-collapse
      > .ant-collapse-item.ant-collapse-no-arrow
      > .ant-collapse-header {
      padding-left: 0;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
  .setting-user-list-container {
    overflow: scroll;
    overflow-x: hidden;
    // height: 285px;
    max-height: 280px;

    :last-child.settings-user-row-container {
      margin-bottom: 0;
    }
    .settings-user-row-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .settings-user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        .user-names-role {
          display: flex;
          flex-direction: column;
          span {
            line-height: 16px;
            font-size: 16px;
            font-weight: 500;
            color: #393939;
          }
          p {
            line-height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #7a7a7a;
          }
        }
      }
      .settings-user-btns {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .delete-user-btn {
          button {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 10px;
            background-color: $bg-color-lighter;
            img {
              width: 18px;
            }
          }
          button:disabled,
          button[disabled] {
            background-color: #cccccc;
            opacity: 0.5;
            color: #666666;
          }
        }
        .set-as-admin-btn {
          margin-right: 16px;
          button {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 10px;
            background-color: $bg-btn-greenlighter;
          }
        }
        .set-as-member-admin-btn {
          margin-right: 16px;
          button {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 10px;
            background-color: #ffd78b;
          }
          button:disabled,
          button[disabled] {
            background-color: #cccccc;
            opacity: 0.5;
            color: #666666;
          }
        }
      }
    }
  }
}

.message-settings-container {
  width: 36px;
  height: 36px;
  margin-left: 4px;
  align-self: flex-end;
  border-radius: 50%;
  display: none;
  .message-settings-btn {
    background-color: #f8f8f8;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: none;
    visibility: hidden;
    &::after {
      content: none;
    }
    &::before {
      content: none;
    }
    img {
      width: 15px;
    }
  }
  .menu-setting-item {
    display: flex;
    color: #7f7f7f;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 5px 15px 5px 15px;
    &:hover {
      cursor: pointer;
      background-color: $bg-color-lighter;
    }
  }
  .dropdown-menu {
    border-radius: 20px;
    padding: 15px 0px 15px 0px;
  }
  .dropdown-item {
    color: #7f7f7f;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  .chat-forward-button {
    background-color: #f8f8f8;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
  }
}

.send-img-chat-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-container {
    margin-bottom: 20px;
  }
  .send-buttons {
    display: flex;
    flex-direction: row;
  }
}

.background-settings-chat-modal {
  .background-image-pick {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 20px;
    p {
      color: #3b3b3b;
      font-size: 16px;
      line-height: 12px;
    }
    .settings-background-button {
      button {
        width: 20px;
        height: 46px;
        border: none;
        border-radius: 10px;
        background-color: #ffffff;
      }
      p {
        color: #3b3b3b;
        font-size: 16px;
        line-height: 12px;
      }
    }
  }
  .background-settings-default {
    margin-bottom: 30px;
    button {
      border: none;
      background-color: #ffffff;
      padding: 0;
    }
    p {
      color: $primary-color;
      font-size: 16px;
      line-height: 12px;
    }
  }
  .background-settings-images {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    div {
      height: 82px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.1);
      img {
        height: 100%;
        width: 100%;
      }
      .bg-check-indicator {
        box-shadow: none;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6eaed;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        img {
          filter: invert(15%) sepia(92%) saturate(2220%) hue-rotate(328deg)
            brightness(98%) contrast(85%);
        }
      }
    }
  }
  .background-settings-save {
    display: flex;
    flex-direction: row;
  }
}

.forward-msg-modal {
  .forward-message-search-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px 20px 0px;
    .search-input {
      background-color: #f8f8f8;
      width: 365px;
      height: 40px;
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
    display: flex;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(158, 39, 67, 0.1);
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9e2743;
    max-height: 40px;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #9e2743;
  }
}

.settings-delete-chat {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete-chat-btn {
  margin-bottom: 20px;
  button {
    width: 200px;
    height: 46px;
    border: none;
    border-radius: 10px;
    background-color: $bg-color-lighter;
  }
  p {
    color: $primary-color;
    font-size: 16px;
    line-height: 14px;
  }
}

.save-chat-btn {
  margin-bottom: 20px;
  margin-right: 16px;
  button {
    width: 200px;
    height: 46px;
    border: none;
    border-radius: 10px;
    background-color: $bg-color-lighter;
  }
  p {
    color: $primary-color;
    font-size: 16px;
    line-height: 12px;
    font-weight: 500;
  }
}

.cancel-chat-btn {
  margin-bottom: 20px;
  button {
    width: 200px;
    height: 46px;
    border: none;
    border-radius: 10px;
    background-color: #eaeaea;
  }
  p {
    color: #3b3b3b;
    font-size: 16px;
    line-height: 12px;
    font-weight: 500;
  }
}

.upload-image-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-color: rgba(158, 39, 67, 0.1);
  border-radius: 50%;
  img {
    align-self: center;
    max-width: 100px;
    width: auto;
    height: auto;
  }
  .avatar-current-image {
    .avatar-noImage {
      align-self: center;
      p {
        position: relative;
        color: $primary-color;
        text-align: center;
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
  .avatar-imageWrap {
    cursor: pointer;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    max-width: 100px;
    width: auto;
    height: auto;
  }
}

.current-image-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: rgba(158, 39, 67, 0.1);
  border-radius: 50%;
  img {
    align-self: center;
    max-width: 100px;
    width: auto;
    height: auto;
  }
  .avatar-imageWrap {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    max-width: 100px;
    width: auto;
    height: auto;
  }
  .avatar-noImage {
    align-self: center;
    p {
      position: relative;
      color: $primary-color;
      text-align: center;
      font-size: 30px;
      line-height: 30px;
    }
  }
}

.add-users-header-info {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .chat-modal-search-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .search-input {
      background-color: #f8f8f8;
      color: #7a7a7a;
      width: 70%;
      input {
        margin-top: 0px;
      }
    }
  }
}

.set-header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  .input-mask-field {
    .input-container {
      margin: 0px;
      input {
        background-color: #f8f8f8;
        color: #7a7a7a;
        margin-top: 0px;
        height: 40px;
        padding-left: 15px;
      }
      ::placeholder {
        color: #7a7a7a;
        vertical-align: middle;
      }
    }
  }
  .chat-modal-search-row {
    display: flex;
    flex-direction: row;
    .search-input {
      background-color: #f8f8f8;
      color: #7a7a7a;
      input {
        margin-top: 0px;
      }
    }
  }
}

.user-list-container {
  margin-bottom: 20px;
  overflow: scroll;
  overflow-x: hidden;
  height: 310px;
  padding-right: 40px;
  .user-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding: 15px 0px 15px 0px;
    label {
      margin-bottom: 0;
    }
    .user-info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
      }
    }
    input[type="checkbox"] {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      width: 1px;
    }
    .checkbox {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      background: #fff;
      border: 2px rgba(178, 179, 183, 1) solid;
      margin-right: 4px;
      cursor: pointer;
    }
    .checkbox--active {
      border-color: rgba(158, 39, 67, 1);
      background: fff;
      cursor: pointer;
    }
  }

  .load-more-btn {
    margin: 10px 0;
    cursor: pointer;
    color: $primary-color;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
  }
}

.chats-list-container {
  margin-bottom: 20px;
  overflow: scroll;
  overflow-x: hidden;
  height: 310px;
  padding-right: 40px;
  .chat-item-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding: 15px 0px 15px 0px;
    label {
      margin-bottom: 0;
    }
    .chat-info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
      }
    }
    input[type="checkbox"] {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      width: 1px;
    }
    .checkbox {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      background: #fff;
      border: 2px rgba(178, 179, 183, 1) solid;
      margin-right: 4px;
      cursor: pointer;
    }
    .checkbox--active {
      border-color: rgba(158, 39, 67, 1);
      background: fff;
      cursor: pointer;
    }
  }
}

.form__add-chat {
  margin-top: 0px;
}

.users__add-chat {
  display: flex;
  justify-content: center;
}

.input-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  input[type="checkbox"] {
    border-radius: 2px;
    -webkit-appearance: none;
    background: rgba(158, 39, 67, 0.1);
    margin-right: 5px;
    &:checked {
      background: rgba(158, 39, 67, 1);
    }
  }
  .check-mark {
    position: absolute;
  }
}

.infinite-scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.infinite-scroll-container::-webkit-scrollbar {
  display: none;
}

.chat__avatar {
  height: 105px;
  width: 105px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-width: 1px solid;
  cursor: pointer;
  background-color: #f9e5ea;

  img {
    height: 105px;
    width: 105px;
    object-fit: fill;
  }

  .default-img {
    width: 55px;
    height: 55px;
  }

  .avatar-noImage {
    align-self: center;
    p {
      position: relative;
      color: $primary-color;
      text-align: center;
      font-size: 30px;
      line-height: 30px;
    }
  }
}
