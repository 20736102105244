.filter-user{
  margin-left: 15px;
  width: 175px;
  // padding: 15px;
  .filter-icon{
    display: none;
  }
  .filter{
    // display: none;
    .form__form-group{
      margin-bottom: 10px;
    }
  }
  
  .profile__data_card {
    margin-bottom: 10px;
  }

  .profile__data-tooltip {
    margin-bottom: 3px;
  }

  .form__form-group .label {
    margin-bottom: 3px;
  }
}

.sidebar--collapse{
  .filter-user{
    .filter{
      // display: none;
    }
    .filter-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}