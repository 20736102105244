.profile {
  //  margin: 10px 10px;
  margin-left: 10px;
}

.profile__card {
  margin: 0px 5px 0px 5px;
  height: 100%;
  min-width: 335px;
  border-radius: 20px;
  padding: 0;
  @include themify($themes) {
    background-color: themed("colorBackground");
  }
  .nav-item {
    font-weight: 500;
    // padding: 10px 10px;
    font-size: 16px;
    line-height: 16px;
  }
  .tab-content {
    padding: 0 30px 40px 30px;
    // min-height: 490px;
  }
  .timeline__content {
    @include themify($themes) {
      background: themed("colorBackgroundBody");
    }
    &:after {
      @include themify($themes) {
        border-left-color: themed("colorBackgroundBody");
      }
    }
  }
  .timeline .timeline__item:nth-child(even) {
    .timeline__content {
      &:after {
        @include themify($themes) {
          border-right-color: themed("colorBackgroundBody");
        }
      }
    }
  }
  &--calendar {
    .rbc-toolbar {
      -webkit-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
  }
}

// .profile__tabs {
//   padding-bottom: 20px;
// }
.profile__information {
  padding: 20px 20px 20px 20px !important;
  display: flex;
  flex-direction: column;
  @include themify($themes) {
    border-bottom: 1px solid themed("colorBorder");
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .profile__data {
    @media screen and (max-width: 320px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
    }
  }
}

.profile__stats {
  display: flex;
  justify-content: space-around;
}

.profile__stat {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 0.5px solid #e5e5e5;
  border-right: 0.5px solid #e5e5e5;
  width: 100%;
  &:last-child {
    border-right: none;
  }
}

// .profile__stat :last-child {
//   border-right: none;
// }
.profile__stat-number {
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  // margin: 0;
}

.profile__stat-title {
  // margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: $color-additional-text !important;
}

.profile__data {
  padding: 5px;
  // width: calc(100% - 140px);
}

.profile__name {
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  // text-transform: uppercase;
}

.profile__work {
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.profile__data_card {
  width: 100%;
  // display: flex;
  // align-items: center;
  // background: #f5f5f5;
  // border-color: #d9d9d9;
  // border-radius: 10px;
  // padding-right: 10px;
  .contact-copy {
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    right: 10px;
    bottom: 33px;
  }
  .ant-picker {
    margin-top: 0px !important;
  }

  // .profile__data-tooltip {
  //   margin-bottom: 10px !important;
  // }
}

.profile__data-tooltip {
  font-size: 12px;

  // line-height: 1.7;
  color: $color-additional-text !important;
}

.profile__contact {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 16px;
  background-color: $color-secondary;
  border-radius: 10px;
  padding: 15px 20px;
}

.profile__action-card {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  // justify-content: center;
}

.profile__action-status {
  margin-left: 5px;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 20px;
  color: $color-additional-text;
}

.profile__action-btns {
  display: flex;
  justify-content: space-between;
}

.profile__btn {
  // padding: 16px 22px;
  margin: 5px;
  font-size: 16px;
  width: 142px;
}

.save-profile__btn {
  font-size: 16px;
  max-width: 205px;
  // min-width: 200px;
  padding: 16px 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  &:before {
    transition: none;
    transform: none;
  }
}

.profile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;
  &:last-child {
    border: none;
  }
  img {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    margin-top: 10px;
    width: auto;
    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.profile__activity-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 64px;
  margin-right: 20px;
  img {
    height: 64px;
    width: 64px;
    margin: 0;
  }
}

.profile__activity-name {
  font-weight: 700;
  color: $color-blue;
}

.profile__activity-date {
  font-weight: 400;
  color: $color-additional;
  font-size: 10px;
}

.profile__current-tasks-title {
  padding: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  @include themify($themes) {
    border-bottom: 1px solid themed("colorBorder");
  }
  span {
    color: $color-additional;
    font-size: 13px;
  }
}

.profile__current-tasks {
  position: relative;
  padding-bottom: 40px;
}

.profile__current-task {
  display: flex;
  margin: 0 20px;
  padding: 10px 0 10px 28px;
  position: relative;
  @include themify($themes) {
    border-bottom: 1px solid themed("colorBorder");
  }
  &:last-of-type {
    border-bottom: none;
  }
  .badge {
    text-transform: uppercase;
    font-size: 9px;
    padding: 6px 10px;
    display: inline-table;
    height: 20px;
    color: #ffffff;
    margin-left: 5px;
    &.badge-info {
      background-color: $color-accent;
    }
    &.badge-error {
      background-color: $color-red;
    }
  }
}

.profile__current-task-checkbox {
  position: absolute;
  left: 0;
}

.profile__see-all-tasks {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
