.add-taxonomy-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 68px;

  .input-label {
    margin-bottom: 10px;
  }

  .input-mask-field {
    margin-bottom: 0;
  }
  .add-taxonomy-field_icon {
    &:hover {
      background-color: transparent;
    }
  }
}

.add-taxonomy-icons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 48px;
  align-self: flex-end;
}

.ant-dropdown-menu-item {
  font-size: 12px;
  padding: 0 0 10px 0;
  &:last-child {
    padding: 0;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
  color: #9e2743;
}

.ant-dropdown-menu.selected-tasks {
  padding: 10px;
}

.ant-dropdown-menu.selected-elements {
  padding: 10px;
}
